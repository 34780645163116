import { uniqBy } from 'lodash';
import { ANGLE_TOLERANCE_DEGREES } from '../constants';
export const calculateAngle = (point1, point2) => Math.atan2(point2.y - point1.y, point2.x - point1.x);
export const normalizeAngle = (angleRadians) => {
    // Ensure angleDifference is within [0, 2π]
    angleRadians = angleRadians % (2 * Math.PI);
    // If the difference is greater than π, use the smaller complementary angle
    if (angleRadians > Math.PI) {
        angleRadians = 2 * Math.PI - angleRadians;
    }
    return angleRadians;
};
// Loop through each line, for each line find all walls that are not in my room.
// For the 4 points, are all 4 points collinear (they share the same infinite line)?
// If so order the points along line, create a new set of walls by linking each point to the next point e.g. given 4 points we get 3 lines. *---*---*---*
// Filter out:
//   - Any non-unique points that would create a 0 length line
//   - My original line if there is an absolute interseciton
//   - Lines that do not intersect my original line (collinear points do not necessarily intersect, only get me those line segments that are inside of me)
export const createNewTouchingWalls = (wallA, otherWalls) => {
    const segments = [];
    otherWalls.map(x => {
        const points = [
            { x: wallA.p1.x, y: wallA.p1.y },
            { x: wallA.p2.x, y: wallA.p2.y },
            { x: x.p1.x, y: x.p1.y },
            { x: x.p2.x, y: x.p2.y }
        ];
        if (!arePointsCollinear(points, ANGLE_TOLERANCE_DEGREES))
            return [];
        const sortedPoints = orderPointsAlongWall(wallA, points);
        for (let i = 0; i < sortedPoints.length - 1; i++) {
            // If 2 of the 4 points share the same spot no need to create a 0 length line
            if (sortedPoints[i].x === sortedPoints[i + 1].x && sortedPoints[i].y === sortedPoints[i + 1].y)
                continue;
            const segment = {
                uuid: crypto.randomUUID(),
                p1: { x: sortedPoints[i].x, y: sortedPoints[i].y },
                p2: { x: sortedPoints[i + 1].x, y: sortedPoints[i + 1].y }
            };
            segments.push(segment);
        }
    });
    const segmentKey = (segment) => {
        // We want to remove lines that are the same regardless of direction.
        const sortedPoints = [
            { x: segment.p1.x, y: segment.p1.y },
            { x: segment.p2.x, y: segment.p2.y }
        ].sort((a, b) => (a.x === b.x ? a.y - b.y : a.x - b.x));
        return `${sortedPoints[0].x}-${sortedPoints[0].y}-${sortedPoints[1].x}-${sortedPoints[1].y}`;
    };
    const filtered = uniqBy(segments, x => segmentKey(x))
        .filter(x => !(x.p1.x === wallA.p1.x && x.p2.x === wallA.p2.x && x.p1.y === wallA.p1.y && x.p2.y === wallA.p2.y) && // The line is not my original line
        isLineInsideAnother(wallA, x, ANGLE_TOLERANCE_DEGREES) // The line is inside my original line dimensions
    );
    const filterOutBiggerOverlappingLines = filtered.filter(x => !(filtered.filter(s => s.uuid !== x.uuid).some(s => isLineInsideAnother(x, s, ANGLE_TOLERANCE_DEGREES))));
    const sortedPoints = orderPointsAlongWall(wallA, filterOutBiggerOverlappingLines.map(x => ({ x: x.p1.x, y: x.p1.y })));
    return sortedPoints.map(sp => filterOutBiggerOverlappingLines.find(fp => sp.x === fp.p1.x && sp.y === fp.p1.y));
};
const orderPointsAlongWall = (wallLine, points) => {
    const refPoint = points[0];
    const dx = wallLine.p2.x - wallLine.p1.x;
    const dy = wallLine.p2.y - wallLine.p1.y;
    // Function to compute projection of a point on the wallA direction
    const projection = (point) => {
        const { x: px, y: py } = point;
        const { x: rx, y: ry } = refPoint;
        // Calculate the projection using the dot product
        return ((px - rx) * dx + (py - ry) * dy) / Math.sqrt(dx * dx + dy * dy);
    };
    // Sort points based on their projection on the wallA direction
    const pointsSorted = points.slice().sort((a, b) => {
        return projection(a) - projection(b);
    });
    return pointsSorted;
};
export const isPointOnLineSegment = (wallLine, point, tolerance) => {
    const dxLine = wallLine.p2.x - wallLine.p1.x;
    const dyLine = wallLine.p2.y - wallLine.p1.y;
    const dxPoint = point.x - wallLine.p1.x;
    const dyPoint = point.y - wallLine.p1.y;
    // Check if the point is behind the start of the line segment
    const dotProduct = dxPoint * dxLine + dyPoint * dyLine;
    if (dotProduct < 0)
        return false;
    // Check if the point is beyond the end of the line segment
    const squaredLengthBA = dxLine * dxLine + dyLine * dyLine;
    if (dotProduct > squaredLengthBA)
        return false;
    const isCollinear = arePointsCollinear([
        { x: wallLine.p1.x, y: wallLine.p1.y },
        { x: wallLine.p2.x, y: wallLine.p2.y },
        point
    ], tolerance);
    if (!isCollinear)
        return false;
    // If all checks pass, the point is on the line segment
    return true;
};
export const isLineInsideAnother = (line1, line2, tolerance) => isPointOnLineSegment(line1, { x: line2.p1.x, y: line2.p1.y }, tolerance) && isPointOnLineSegment(line1, { x: line2.p2.x, y: line2.p2.y }, tolerance);
export const arePointsCollinear = (points, tolerance) => {
    const uniquePoints = uniqBy(points, x => `${x.x}-${x.y}`);
    if (uniquePoints.length < 3)
        return true;
    const slope = (p1, p2) => {
        if (p1.x === p2.x)
            return Infinity;
        return (p2.y - p1.y) / (p2.x - p1.x);
    };
    const calculateAngleBetweenSlopes = (slope1, slope2) => {
        if (slope1 === slope2)
            return 0;
        if (slope1 === Infinity && slope2 === Infinity)
            return 0;
        if ((slope1 === 0 && slope2 === Infinity) || (slope1 === Infinity && slope2 === 0))
            return 90;
        const angleRad = Math.atan(Math.abs((slope1 - slope2) / (1 + slope1 * slope2)));
        const angleDeg = angleRad * (180 / Math.PI);
        return angleDeg;
    };
    const isCollinear = (p1, p2, p3) => {
        if ((p1.x === p2.x && p2.x === p3.x) || (p1.y === p2.y && p2.y === p3.y))
            return true;
        const slopeAB = slope(p1, p2);
        const slopeBC = slope(p2, p3);
        const angleBetween = calculateAngleBetweenSlopes(slopeAB, slopeBC);
        return angleBetween <= tolerance || (180 - angleBetween) <= tolerance;
    };
    // Use the first two points to establish the line
    const basePoint = uniquePoints[0];
    const secondPoint = uniquePoints[1];
    return uniquePoints.slice(2).every(x => isCollinear(basePoint, secondPoint, x));
};
