import React, { useState } from 'react';
import { getRadiatorHeightWidthText } from '../../code/models/radiator';
import { Button } from '../../components/buttons/button';
import { Input } from '../../components/inputs_and_selections/input';
import { PhotoScroll } from '../../components/inputs_and_selections/photo_scroll';
import { ClickableCard } from '../../components/content_display/card';
import { Badge } from '../../components/indicators_and_messaging/badge';
import { RadioGroup } from '../../components/inputs_and_selections/radio';
import { TextArea } from '../../components/inputs_and_selections/text_area';
import { SelectRadiatorModelInner } from './design/pages/radiator_model_selector';
import { getPipeModelName, PIPE_MODELS } from '../../code/models/pipes';
import { Select } from '../../components/inputs_and_selections/select';
export const RadiatorPage = ({ radiator, setRadiator, customRadiatorModels, setCustomRadiatorModels, onSave, files, setFiles, deltaTFlowReturnC, roomTemp, flowTemp, setPage, page, setHeader, companyUuid, onBack }) => {
    var _a, _b;
    const images = radiator.photos.map(x => files.find(y => y.uuid === x.image_uuid));
    const currentPipe = PIPE_MODELS.find(x => x.uuid === radiator.pipe_model_uuid);
    const [currentPipeMaterial, setCurrentPipeMaterial] = useState((_a = currentPipe === null || currentPipe === void 0 ? void 0 : currentPipe.material) !== null && _a !== void 0 ? _a : 'Copper');
    const deleteImage = (imageUUID) => {
        setRadiator(prev => ({ ...prev, photos: prev.photos.filter(x => x.image_uuid !== imageUUID) }));
        setFiles(prev => prev.map(x => x.uuid === imageUUID ? { ...x, deleted_at: new Date().getTime(), updated_at: new Date().getTime() } : x));
    };
    if (page === 'RADIATOR_MODEL' || page === 'ADD_CUSTOM_RADIATOR') {
        return React.createElement(SelectRadiatorModelInner, { customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, originallySelectedModelUuid: (_b = radiator.radiator_type) === null || _b === void 0 ? void 0 : _b.uuid, deltaTFlowReturnC: deltaTFlowReturnC, roomTemp: roomTemp, flowTemp: flowTemp, companyUuid: companyUuid, addRadiator: (rm) => {
                onBack();
                setRadiator(prev => ({ ...prev, radiator_type_uuid: rm.uuid, radiator_type: rm, updated_at: new Date().getTime() }));
            }, setPage: setPage, page: page, onBack: onBack });
    }
    return React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: "p-5 bg-white flex-col gap-5 flex overflow-y-auto flex-grow" },
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Radiator type"),
                React.createElement(ClickableCard, { size: 'SM', variant: 'WHITE', onClick: () => setPage('RADIATOR_MODEL') },
                    React.createElement("div", { className: 'flex justify-between text-sm items-center' },
                        React.createElement("div", null, radiator.radiator_type.type),
                        React.createElement(Badge, { color: 'INDIGO', text: getRadiatorHeightWidthText(radiator.radiator_type) })))),
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Maximum possible size"),
                React.createElement("div", { className: 'flex gap-2 text-sm text-gray-600' },
                    React.createElement("div", { className: 'flex-1 flex flex-col gap-1' },
                        React.createElement("div", null, "Height"),
                        React.createElement(Input, { type: 'number', value: radiator.maximum_height.toString(), setValue: (e) => setRadiator(prev => ({ ...prev, maximum_height: parseFloat(e) })), postfix: React.createElement("span", null, "mm") })),
                    React.createElement("div", { className: 'flex-1 flex flex-col gap-1' },
                        React.createElement("div", null, "Width"),
                        React.createElement(Input, { type: 'number', value: radiator.maximum_width.toString(), setValue: (e) => setRadiator(prev => ({ ...prev, maximum_width: parseFloat(e) })), postfix: React.createElement("span", null, "mm") })))),
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Pipe material"),
                React.createElement(RadioGroup, { items: [
                        {
                            name: 'Copper',
                            onClick: () => setCurrentPipeMaterial('Copper'),
                            variant: currentPipeMaterial === 'Copper' ? 'ACTIVE' : 'DEFAULT'
                        },
                        {
                            name: 'PEX',
                            onClick: () => setCurrentPipeMaterial('PEX'),
                            variant: currentPipeMaterial === 'PEX' ? 'ACTIVE' : 'DEFAULT'
                        },
                        {
                            name: 'MLCP',
                            onClick: () => setCurrentPipeMaterial('MLCP'),
                            variant: currentPipeMaterial === 'MLCP' ? 'ACTIVE' : 'DEFAULT'
                        }
                    ] })),
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Pipe diameter"),
                React.createElement(Select, { options: PIPE_MODELS.filter(x => x.material === currentPipeMaterial).map(x => ({
                        key: x.uuid,
                        value: getPipeModelName(x)
                    })), selectedKey: radiator.pipe_model_uuid, setSelectedKey: (e) => setRadiator(prev => ({ ...prev, pipe_model_uuid: e })) })),
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Photos"),
                React.createElement(PhotoScroll, { images: images, addImage: (fileWrapper) => {
                        fileWrapper && setFiles([...files, fileWrapper]);
                        fileWrapper && setRadiator(prev => ({
                            ...prev,
                            photos: [...prev.photos, {
                                    uuid: crypto.randomUUID(),
                                    entity_uuid: prev.uuid,
                                    image_uuid: fileWrapper.uuid
                                }]
                        }));
                    }, deleteImage: deleteImage, companyUUID: companyUuid })),
            React.createElement("div", { className: 'flex flex-col gap-2 flex-grow' },
                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Notes"),
                React.createElement(TextArea, { value: radiator.notes.toString(), setValue: (e) => setRadiator(prev => ({ ...prev, notes: e })), size: 'SM' }))),
        React.createElement("div", { className: 'p-4 bg-white shadow-md' },
            React.createElement(Button, { block: true, onClick: () => {
                    onSave();
                    setRadiator(undefined);
                    onBack();
                } }, "Apply")));
};
