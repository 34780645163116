import React from 'react';
import { Layer, Line } from 'react-konva';
import { gray200, indigo600 } from './code/constants';
const GRIDS = {
    MM: 1,
    CM: 10,
    M: 1000
};
export const CANVAS_SIZE = 100000;
export const GridLayer = ({ stageScale }) => {
    const oneMetre = 1000;
    return React.createElement(Layer, null,
        React.createElement(Line, { points: [-oneMetre * stageScale, 0, oneMetre * stageScale, 0], stroke: indigo600, opacity: 0.5 }),
        React.createElement(Line, { points: [0, -oneMetre * stageScale, 0, oneMetre * stageScale], stroke: indigo600, opacity: 0.5 }));
};
// Grid is 100m x 100m
export const Grid = ({ size, scale, position, stageWidth, stageHeight }) => {
    const gridIncrementPixels = GRIDS[size] * scale;
    const gridSize = 100 * (1000 / GRIDS[size]);
    if (gridIncrementPixels < 15)
        return;
    const viewportWidth = stageWidth;
    const viewportHeight = stageHeight;
    const { x: stageX, y: stageY } = position;
    const minX = -stageX;
    const minY = -stageY;
    const maxX = -stageX + viewportWidth;
    const maxY = -stageY + viewportHeight;
    const startingPosition = -((gridIncrementPixels * gridSize) / 2);
    const endPosition = gridIncrementPixels * gridSize + startingPosition;
    const verticalLines = [];
    for (let i = 0; i < gridSize + 1; i++) {
        const x = i * gridIncrementPixels + startingPosition;
        if (x >= minX && x <= maxX) {
            const line = React.createElement(Line, { key: i, opacity: 0.5, points: [x, startingPosition, x, endPosition], stroke: gray200 });
            verticalLines.push(line);
        }
    }
    const horizontalLines = [];
    for (let i = 0; i < gridSize + 1; i++) {
        const startingPosition = -((gridIncrementPixels * gridSize) / 2);
        const y = i * gridIncrementPixels + startingPosition;
        if (y >= minY && y <= maxY) {
            const line = React.createElement(Line, { key: i, opacity: 0.5, points: [startingPosition, y, endPosition, y], stroke: gray200 });
            horizontalLines.push(line);
        }
    }
    return React.createElement(React.Fragment, null,
        verticalLines,
        horizontalLines);
};
