import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { getAddressIncludingPostcode, getLeads } from '../../code/models/lead';
import { orderBy } from 'lodash';
import { Bars3Icon } from '@heroicons/react/16/solid';
import { truncateString } from '../../code/helpers';
import { EnquiriesListItemOptions } from '../survey/components/enquiries_list_item_options';
import { Badge, leadStatusToBadge } from '../../components/indicators_and_messaging/badge';
import { AdminContext } from './admin_layout';
import { calculateQuote } from '../../code/calculate_quote';
import { Link } from '../../components/buttons/link';
import { formatPrice } from '../../code/format_price';
import { OfflinePage } from './offline_page';
import { contextSensitiveFormatDate } from '../../code/format_date';
import { getRoleForCompany, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE, USER_ROLE_SURVEYOR } from '../../code/models/user';
import { RequireRole } from '../../require_role';
export const LeadsListPage = ({ filter, hideFilterBar, title }) => {
    var _a;
    const navigate = useNavigate();
    const companyPublicInfo = useRouteLoaderData('company_root');
    const adminContext = useContext(AdminContext);
    const [leads, setLeads] = React.useState([]);
    // The leads list page (/admin/enquries) is the default landing page for all users after login.
    // However, surveyors never have access to this page, so once we're here (and we have a user context
    // we can use to check the user's role), we can redirect them to the surveys page if they're a surveyor.
    const companyRole = getRoleForCompany((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.user, companyPublicInfo.subdomain);
    if (companyRole === USER_ROLE_SURVEYOR) {
        navigate(`/${companyPublicInfo.subdomain}/admin/surveys`);
    }
    // filter leads
    const filteredLeads = orderBy(leads.filter(x => 
    // if filter is inprogress then show all quotes that are not rejected
    // otherwise show all quotes that are rejected
    (filter === 'All') ? x.status !== 'Archived' : x.status === filter), x => new Date(x.createdAt), 'desc');
    const leadsPrices = useMemo(() => {
        var _a, _b, _c;
        if (leads.length === 0)
            return {};
        if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company))
            return {};
        if (!((_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.heatPumps))
            return {};
        if (!((_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.hotWaterCylinders))
            return {};
        return leads.map((lead) => {
            var _a, _b, _c, _d, _e;
            const totalPrice = calculateQuote(lead, (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.heatPumps) !== null && _b !== void 0 ? _b : [], (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.hotWaterCylinders) !== null && _d !== void 0 ? _d : [], (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company).totalPrice;
            return {
                [lead.uuid]: totalPrice
            };
        }).reduce((acc, curr) => {
            return {
                ...acc,
                ...curr
            };
        });
    }, [leads, adminContext]);
    useEffect(() => {
        const main = async () => {
            const allLeads = await getLeads(companyPublicInfo.uuid);
            setLeads(allLeads || []);
        };
        main();
    }, [companyPublicInfo]);
    const filterOptions = [
        {
            urlPath: `/${companyPublicInfo.subdomain}/admin/enquiries`,
            label: 'All'
        },
        {
            urlPath: `/${companyPublicInfo.subdomain}/admin/enquiries/inprogress`,
            label: leadStatusToBadge('New').text
        },
        {
            urlPath: `/${companyPublicInfo.subdomain}/admin/enquiries/estimated`,
            label: leadStatusToBadge('Quoted').text
        },
        {
            urlPath: `/${companyPublicInfo.subdomain}/admin/enquiries/accepted`,
            label: leadStatusToBadge('Converted').text
        },
        {
            urlPath: `/${companyPublicInfo.subdomain}/admin/enquiries/rejected`,
            label: leadStatusToBadge('Rejected').text
        },
        {
            urlPath: `/${companyPublicInfo.subdomain}/admin/enquiries/declined`,
            label: leadStatusToBadge('Declined').text
        },
        {
            urlPath: `/${companyPublicInfo.subdomain}/admin/enquiries/survey_booked`,
            label: leadStatusToBadge('SurveyBooked').text
        },
        {
            urlPath: `/${companyPublicInfo.subdomain}/admin/enquiries/archived`,
            label: leadStatusToBadge('Archived').text
        }
    ];
    if (adminContext.isOffline) {
        return React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys/all` });
    }
    return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS] },
        React.createElement("div", { className: 'p-8' },
            React.createElement("div", { className: "max-lg:hidden flex bg-white justify-between items-center" },
                companyPublicInfo.logo && React.createElement("img", { src: companyPublicInfo.logo, className: "max-h-12", alt: "Installer logo" }),
                React.createElement("div", { className: "justify-start items-center gap-3 flex" },
                    React.createElement("div", { className: "justify-start items-center gap-1 flex" }))),
            React.createElement("div", { className: "flex flex-row py-5 justify-between items-center" },
                React.createElement("div", { className: "justify-start items-center gap-6 flex" },
                    React.createElement(Bars3Icon, { "data-cy": 'nav_open', className: "w-6 h-6 relative lg:hidden", onClick: () => { adminContext.showSidebar(); } }),
                    React.createElement("div", { className: "lg:text-4xl text-gray-900 text-2xl font-extrabold" }, title))),
            !hideFilterBar && React.createElement("div", { className: "pb-4 flex-col justify-start items-start gap-2.5 flex overflow-x-auto relative no-scrollbar" },
                React.createElement("div", { className: "justify-start items-start gap-2 inline-flex" }, filterOptions.map((option, idx) => {
                    return React.createElement("div", { key: idx, className: `${location.pathname === option.urlPath ? 'bg-gray-200' : ''} p-2.5 rounded-md justify-center items-center flex-shrink-0 cursor-pointer`, onClick: (e) => {
                            navigate(option.urlPath);
                        } },
                        React.createElement("div", { className: "px-2 justify-start items-center flex" },
                            React.createElement("div", { className: "text-gray-900 text-sm font-semibold select-none" }, option.label)));
                }))),
            React.createElement("div", { className: "lg:border lg:rounded overflow-x-auto" },
                React.createElement("table", { className: "table-auto w-full" },
                    React.createElement("thead", { className: "max-lg:hidden" },
                        React.createElement("tr", { className: "border-b border-gray-300" },
                            React.createElement("th", { className: "pl-5 py-5 text-gray-600 text-xs font-bold uppercase text-left" }, "Customer"),
                            React.createElement("th", { className: "py-5 px-5 text-gray-600 text-xs font-bold uppercase text-left" }, "Contact"),
                            React.createElement("th", { className: "py-5 px-5 text-gray-600 text-xs font-bold uppercase text-left" }, "Received"),
                            React.createElement("th", { className: "py-5 px-5 text-gray-600 text-xs font-bold uppercase text-left" }, "Notes"),
                            React.createElement("th", { className: "py-5 px-5 text-gray-600 text-xs font-bold uppercase text-left" }, "Estimate"),
                            React.createElement("th", { className: "py-5 px-5 text-gray-600 text-xs font-bold uppercase text-left" }, "Status"),
                            React.createElement("th", { className: "py-5 px-5 text-gray-600 text-xs font-bold uppercase text-left" }))),
                    React.createElement("tbody", null, filteredLeads.map((lead, idx) => {
                        var _a, _b, _c;
                        return React.createElement("tr", { key: idx, className: "border-b border-gray-300" },
                            React.createElement("td", { "data-cy": "desktop-lead-row", className: "max-lg:hidden px-5 py-4 pl-5" },
                                React.createElement("div", { className: "flex-col justify-start items-start" },
                                    ((_a = lead.customer) === null || _a === void 0 ? void 0 : _a.name) && React.createElement(Link, { text: lead.customer.name, onClick: () => {
                                            navigate(`/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}`);
                                        } }),
                                    React.createElement("div", { className: "text-gray-500 text-sm font-medium" }, getAddressIncludingPostcode(lead)))),
                            React.createElement("td", { className: "max-lg:hidden px-5 text-gray-500 text-sm" },
                                React.createElement("div", { className: 'flex flex-col gap-y-1' },
                                    React.createElement("div", null, lead.customer.email),
                                    React.createElement("div", null, lead.customer.phone))),
                            React.createElement("td", { className: "max-lg:hidden px-5 text-gray-500 text-sm" }, contextSensitiveFormatDate(new Date(lead.createdAt))),
                            React.createElement("td", { className: "max-lg:hidden px-5 text-gray-500 text-sm" }, truncateString((_b = lead.notes) !== null && _b !== void 0 ? _b : '', 20)),
                            React.createElement("td", { className: "max-lg:hidden px-5 text-sm" }, formatPrice(leadsPrices[lead.uuid])),
                            React.createElement("td", { className: "max-lg:hidden px-5 text-sm" },
                                React.createElement("div", { className: 'flex items-center' },
                                    React.createElement(Badge, { ...leadStatusToBadge(lead.status) }))),
                            React.createElement("td", { className: "max-lg:hidden px-5" },
                                React.createElement(EnquiriesListItemOptions, { size: "sm", lead: lead, setLead: (lead) => setLeads(prev => prev.map(x => x.uuid === lead.uuid ? lead : x)) })),
                            React.createElement("td", { "data-cy": "mobile-lead-row", className: "lg:hidden" },
                                React.createElement("div", { className: "self-stretch py-3 bg-white flex-col justify-start items-start gap-2 flex" },
                                    React.createElement("div", { className: "self-stretch justify-between items-center inline-flex" },
                                        ((_c = lead.customer) === null || _c === void 0 ? void 0 : _c.name) && React.createElement(Link, { text: lead.customer.name, onClick: () => {
                                                navigate(`/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}`);
                                            } }),
                                        React.createElement(EnquiriesListItemOptions, { size: "sm", lead: lead, setLead: (lead) => setLeads(prev => prev.map(x => x.uuid === lead.uuid ? lead : x)) })),
                                    React.createElement("div", { className: "self-stretch text-gray-600 text-sm font-medium" }, getAddressIncludingPostcode(lead)),
                                    React.createElement("div", { className: "self-stretch justify-between items-start inline-flex" },
                                        React.createElement("div", { className: "justify-start items-start gap-3 flex" },
                                            React.createElement("div", { className: "text-gray-900 text-sm font-semibold" }, formatPrice(leadsPrices[lead.uuid])),
                                            React.createElement("div", { className: "text-center place-self-center text-gray-500 text-xs font-medium" }, contextSensitiveFormatDate(new Date(lead.createdAt)))),
                                        React.createElement(Badge, { ...leadStatusToBadge(lead.status) })))));
                    })))))));
};
