import { snapPoint } from '../room_drawing';
import { generateComplexRoom } from '../../add_room';
export const stageOnMouseMove = (e, scalingPoints, drawingRooms, scalingWindows, floor, setMousePosition, stageStep, setGuidelines, stageScale, survey) => {
    var _a;
    // If we are adding a complex room.
    if (drawingRooms || stageStep || scalingWindows) {
        const pointerPosition = e.currentTarget.getRelativePointerPosition();
        // Once we have made the line vertical or horizontal, snapped to the canvas or another object, we can set the value.
        const fakeRoom = generateComplexRoom(floor, survey, scalingPoints, 2.4);
        const snappedPoint = snapPoint(pointerPosition, [...floor.rooms, fakeRoom], stageScale, []);
        const newPoint = (_a = snappedPoint === null || snappedPoint === void 0 ? void 0 : snappedPoint.newPoint) !== null && _a !== void 0 ? _a : pointerPosition;
        setMousePosition({ x: Math.round(newPoint.x / stageScale), y: Math.round(newPoint.y / stageScale) });
        snappedPoint ? setGuidelines(snappedPoint.guidelines) : setGuidelines([]);
    }
};
