import React from 'react';
import { Heading } from '../../../components/content_display/heading';
export const HeadingDemo = () => {
    return (React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => (React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description)))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", null, "Sizes"),
            React.createElement("div", { className: 'flex flex-col justify-start items-start gap-4' },
                React.createElement(Heading, { size: "lg" }, "Large heading (h4)"),
                React.createElement(Heading, { size: "xl" }, "Extra large heading (h3)"),
                React.createElement(Heading, { size: "2xl" }, "Double extra large heading (h2)"),
                React.createElement(Heading, { size: "3xl" }, "Triple extra large heading (h1)")),
            React.createElement("div", null, "Custom class example"),
            React.createElement(Heading, { size: "2xl", className: "bg-yellow-300 rounded italic" }, "Custom styled heading"))));
};
const componentProps = [
    { name: 'children', description: 'React.ReactNode or string to be rendered as heading content' },
    { name: 'size', description: 'lg | xl | 2xl | 3xl - Determines both the heading level and text size' },
    { name: 'className', description: 'Additional CSS classes to apply (optional)' }
];
const codeBlock = `<Heading
  size="2xl"
>
  Hello World
</Heading>`;
