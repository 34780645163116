import React, { useEffect } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { calculateLineLength } from './code/utils';
import { Button } from '../../../components/buttons/button';
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header';
import { Input } from '../../../components/inputs_and_selections/input';
export const ScalePage = ({ scalingPoints, tempImageAndScale, scaleLength, setScaleLength, addEvent, floor, resetUploadPlan, setHeader, setStageStep, setScalingPoints }) => {
    var _a;
    useEffect(() => {
        setHeader(React.createElement(BottomSheetHeader, { onBack: () => {
                setStageStep(prev => prev - 1);
                setScalingPoints(prev => prev.slice(0, -1));
            }, title: 'Enter line length' }));
    }, []);
    return React.createElement("div", { className: 'flex flex-col gap-3 p-5' }, scalingPoints.length === 2 && React.createElement("div", { className: 'flex flex-col gap-2' },
        React.createElement("div", { className: 'flex flex-col gap-1' },
            React.createElement("div", { className: 'font-bold text-gray-900' }, "Object length"),
            React.createElement("div", { className: "text-gray-500 text-sm" }, "Enter the length of the object from the floor plan.")),
        React.createElement("div", { className: 'flex flex-col gap-5' },
            React.createElement(Input, { type: 'number', className: 'flex-grow', postfix: 'metres', value: (_a = scaleLength === null || scaleLength === void 0 ? void 0 : scaleLength.toString()) !== null && _a !== void 0 ? _a : '', setValue: (e) => setScaleLength(parseFloat(e)) }),
            React.createElement(Button, { disabled: !(tempImageAndScale === null || tempImageAndScale === void 0 ? void 0 : tempImageAndScale.scale), onClick: () => {
                    const newScale = scaleLength ? scaleLength / (calculateLineLength(scalingPoints[0].x, scalingPoints[0].y, scalingPoints[1].x, scalingPoints[1].y)) : 0;
                    addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, floor_plan_image: tempImageAndScale.image, floor_plan_scale: tempImageAndScale.scale * newScale } }]);
                    resetUploadPlan();
                }, iconRight: faChevronRight }, "Apply"))));
};
