import React, { useContext, useEffect, useRef, useState } from 'react';
import { DesignPageHeader } from '../design/components/design_page_header';
import { faCloudDownload, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../components/buttons/icon';
import { Button } from '../../../components/buttons/button';
import _, { chain, sum } from 'lodash';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { combineHeatLossesForProgressChart, combineSortConductionHeatLosses, combineVentilationHeatLosses, getConductionHeatLossAllElements, getFloorAreaM2, getRoomTemp, getRoomWatts, getVentilationHeatLoss } from '../../../code/models/heat_loss';
import { ClickableCard } from '../../../components/content_display/card';
import { TabGroup } from '../../../components/content_display/tab';
import { TableLite } from '../../../components/content_display/table_lite';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { CompleteButton } from '../survey/complete_button';
import { ListItem } from '../../../components/content_display/list_item';
import { getEmitterWatts } from '../../../code/models/radiator_model';
import { getAreaM2, getEmitterOutputVsDemandText } from './code/utils';
import { pluralise } from '../../../code/helpers';
import { FlowTempSlider } from '../design/pages/emitter_design_page';
import { metropixImportFloorplan } from '../../../code/floorplan_adapters/metropix';
import { Modal } from '../../../components/containers/modal';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { convertMagicplanPlanDetailsToFloors, getMagicplanPlanDetails, getMagicplanPlans } from '../../../code/floorplan_adapters/magicplan';
import { AdminContext } from '../../admin/admin_layout';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { isCompanyHasMagicPlanIntegration } from '../../../code/models/company';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const pages = ['Rooms', 'Heat loss'];
export const FloorList = ({ setFlowTemp, floors, setSurveyTab, setFloorId, minFlowTemp, maxFlowTemp, designTempC, groundTempC, survey, design, setCurrentRoomId, setSurvey }) => {
    const adminContext = useContext(AdminContext);
    const [currentPage, setPage] = useState('Rooms');
    const [showMagicplanModal, setShowMagicplanModal] = useState(false);
    const roomRows = survey.floors.flatMap(x => x.rooms.flatMap(y => {
        return {
            name: y.name,
            designTemp: getRoomTemp(y, survey),
            area: getAreaM2(y.walls.map(w => ({ x: w.x, y: w.y }))),
            heatLoss: getRoomWatts(y, x.rooms, designTempC, groundTempC, survey)
        };
    }));
    const roomColumns = [
        { key: 'name', name: '' },
        { key: 'designTemp', name: 'Design Temp', render: (row) => React.createElement("div", null,
                row.designTemp,
                " \u00B0C") },
        { key: 'area', name: 'Area', render: (row) => React.createElement("div", null,
                row.area.toFixed(1),
                " m\u00B2") },
        { key: 'heatLoss', name: 'Heat loss', render: (row) => React.createElement("div", null,
                row.heatLoss.toFixed(0),
                " W") }
    ];
    const conductionHeatLossColumns = [
        { key: 'elementName', name: '' },
        { key: 'uValueWPerM2K', name: 'U-value (W/m²K)', render: (row) => React.createElement("div", null, row.uValueWPerM2K.toFixed(2)) },
        { key: 'areaM2', name: 'Area', render: (row) => React.createElement("div", null,
                row.areaM2.toFixed(1),
                " m\u00B2") },
        { key: 'watts', name: 'Heat loss', render: (row) => React.createElement("div", null,
                row.watts.toFixed(0),
                " W") }
    ];
    const ventilationHeatLossColumns = [
        { key: 'elementName', name: '' },
        { key: 'ACH', name: 'ACH', render: (row) => React.createElement("div", null, row.ACH) },
        { key: 'volumeM3', name: 'Volume', render: (row) => React.createElement("div", null,
                row.volumeM3.toFixed(1),
                " m\u00B3") },
        { key: 'watts', name: 'Heat loss', render: (row) => React.createElement("div", null,
                row.watts.toFixed(0),
                " W") }
    ];
    const totalFloorAreaM2 = sum(survey.floors.flatMap(floor => floor.rooms.map(room => getFloorAreaM2(room.walls))));
    const conductionHeatLossRows = survey.floors.flatMap(floor => floor.rooms.flatMap(room => getConductionHeatLossAllElements(room, floor.rooms, designTempC, groundTempC, survey)));
    const ventilationHeatLosses = survey.floors.flatMap(floor => floor.rooms.map(room => getVentilationHeatLoss(room, designTempC, survey)));
    // For chart
    const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, ventilationHeatLosses);
    // For tables
    const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows, false);
    const ventilationHeatLossCombinedForTable = combineVentilationHeatLosses(ventilationHeatLosses);
    const totalHeatLossWFromElements = Math.round(sum([...conductionHeatLossRowsCombinedForTable, ...ventilationHeatLossCombinedForTable].map(x => x.watts)));
    const totalHeatLossWFromRooms = Math.round(sum(roomRows.map(x => x.heatLoss)));
    const metropixFileInputRef = useRef(null);
    const page = 'FLOORPLAN';
    if (!adminContext)
        return null;
    return React.createElement("div", { className: 'h-full flex flex-col' },
        React.createElement(MagicplanModal, { survey: survey, setSurvey: setSurvey, showMagicplanModal: showMagicplanModal, setShowMagicplanModal: setShowMagicplanModal }),
        React.createElement(DesignPageHeader, { title: 'Floorplan', onBack: () => setSurveyTab(''), completed: survey.completed_sections.includes(page) },
            React.createElement(TabGroup, { items: pages.map(x => ({
                    name: x,
                    secondaryText: x === 'Heat loss'
                        ? `${totalHeatLossWFromElements} W`
                        : `${totalFloorAreaM2.toFixed(1)} m²`,
                    onClick: () => setPage(x),
                    variant: x === currentPage ? 'ACTIVE' : 'DEFAULT'
                })) })),
        currentPage === 'Rooms' && React.createElement("div", { className: "p-5 bg-gray-100 flex-col gap-6 flex flex-grow overflow-y-auto" },
            React.createElement("div", { className: "flex-col gap-3 flex" },
                React.createElement("div", { className: "justify-between items-center flex" },
                    React.createElement("div", { className: " text-gray-900 text-xl font-bold" }, "Floors"),
                    React.createElement("div", { className: "flex gap-3" },
                        isCompanyHasMagicPlanIntegration(adminContext.data.company) &&
                            React.createElement(Button, { onClick: () => setShowMagicplanModal(prev => !prev), colour: 'LIGHT' },
                                React.createElement(FontAwesomeIcon, { icon: faCloudDownload }),
                                "Magicplan"),
                        React.createElement(Button, { onClick: () => setSurveyTab('NEW_FLOOR'), colour: 'LOZENGE_DARK' }, "Add floor"))),
                React.createElement(MetropixFloorPlanInput, { inputRef: metropixFileInputRef, survey: survey, setSurvey: setSurvey }),
                floors.length === 0 && React.createElement(ClickableCard, { onClick: () => setSurveyTab('NEW_FLOOR'), variant: 'PLACEHOLDER' },
                    React.createElement("div", { className: 'flex flex-col items-center gap-2' },
                        React.createElement(Icon, { className: 'text-2xl', icon: faLayerGroup }),
                        React.createElement("div", null, "No floors found"))),
                React.createElement("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200" }, floors.map(x => React.createElement(ListItem, { onClick: () => setFloorId(x.uuid), key: x.uuid, primaryText: x.name, secondaryText: `${x.rooms.length} room${x.rooms.length > 1 && x.rooms.length !== 0 ? 's' : ''} • ${Math.round(sum(x.rooms.map(y => getAreaM2(y.walls.map(x => ({ x: x.x, y: x.y }))))))} m²` })))),
            floors.length > 0 && React.createElement("div", { className: 'flex flex-col gap-3' },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Rooms"),
                React.createElement(ClickableCard, { border: false, variant: 'WHITE' },
                    React.createElement("div", { className: 'flex flex-col gap-4 justify-between flex-grow' },
                        React.createElement(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp }))),
                floors.map(x => {
                    // If we have a group, group them otherwise group by individual rooms e.g. just list the rooms as normal.
                    const roomGroups = chain(x.rooms)
                        .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
                        .map((values, key) => ({ key, values }))
                        .value();
                    return React.createElement("div", { key: x.uuid },
                        React.createElement("div", { className: "text-gray-500 font-semibold" }, x.name),
                        x.rooms.length === 0 && React.createElement(ClickableCard, { variant: 'PLACEHOLDER' },
                            React.createElement("div", { className: 'flex flex-col items-center gap-2' },
                                React.createElement("div", null, "No rooms found"))),
                        roomGroups.map(({ key, values }) => {
                            const wattsLost = sum(values.map(y => Math.round(getRoomWatts(y, x.rooms, designTempC, groundTempC, survey))));
                            const wattsEmitted = sum(values.map(y => Math.round(sum(y.radiators.map(r => getEmitterWatts(r, y, design, survey, designTempC, groundTempC))))));
                            const windows = sum(values.flatMap(y => y.walls.map(x => x.windows.length)));
                            const doors = sum(values.flatMap(y => y.walls.map(x => x.doors.length)));
                            const roomArea = getAreaM2(values.flatMap(y => y.walls.map(x => ({ x: x.x, y: x.y }))));
                            const wattsPerM2 = Math.round(wattsLost / roomArea);
                            return React.createElement("div", { className: 'flex flex-col gap-3', key: key },
                                values.length > 1 && React.createElement("div", { className: 'flex justify-between' },
                                    React.createElement("div", { className: 'font-bold text-gray-900' }, values.map(x => x.name).join(', ')),
                                    React.createElement(Badge, { color: wattsEmitted >= wattsLost ? 'GREEN' : 'RED', text: getEmitterOutputVsDemandText(wattsEmitted, wattsLost) })),
                                React.createElement("div", { className: 'bg-white rounded-md flex flex-col divide-y divide-gray-200' }, values.map((y, i) => React.createElement(ListItem, { onClick: () => {
                                        setCurrentRoomId(y.uuid);
                                        setFloorId(x.uuid);
                                    }, key: i, primaryText: y.name, secondaryText: `${wattsPerM2} W/m² • ${y.radiators.length} ${pluralise('emitter', y.radiators.length)}, ${windows} ${pluralise('win', windows)}, ${doors} ${pluralise('door', doors)}`, rightBadge: values.length === 1 ? React.createElement(Badge, { color: wattsEmitted >= wattsLost ? 'GREEN' : 'RED', text: getEmitterOutputVsDemandText(wattsEmitted, wattsLost) }) : React.createElement("div", null) }))));
                        }));
                }))),
        currentPage === 'Rooms' && React.createElement(CompleteButton, { page: page, pageChecks: [], survey: survey, setSurvey: setSurvey, onBack: async () => setSurveyTab('') }),
        currentPage === 'Heat loss' && React.createElement("div", { className: 'flex flex-col p-5' },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement(ClickableCard, { variant: 'GREY' },
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        React.createElement("div", { className: 'flex justify-between text-lg' },
                            React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heat loss by room"),
                            React.createElement("div", null,
                                totalHeatLossWFromRooms,
                                " W")),
                        React.createElement(ProgressChart, { total: totalHeatLossWFromRooms, items: roomRows.map(x => ({ value: x.heatLoss, name: x.name })) }))),
                React.createElement(TableLite, { columns: roomColumns, rows: roomRows }),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'flex justify-between text-gray-900 font-bold px-2' },
                        React.createElement("div", null, "Total"),
                        React.createElement("div", null,
                            totalHeatLossWFromRooms,
                            " W")),
                    React.createElement("div", { className: 'flex justify-between px-2 text-xs font-semibold' },
                        React.createElement("div", null, "Heat loss per m\u00B2"),
                        React.createElement("div", null,
                            Math.round(sum(roomRows.map(x => x.heatLoss)) / sum(roomRows.map(x => x.area))),
                            " W/m\u00B2")),
                    React.createElement("div", { className: 'flex justify-between px-2 text-xs font-semibold' },
                        React.createElement("div", null, "Floor area"),
                        React.createElement("div", null,
                            Math.round(sum(roomRows.map(x => x.area))),
                            " m\u00B2"))),
                React.createElement(ClickableCard, { variant: 'GREY' },
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        React.createElement("div", { className: 'flex justify-between text-lg' },
                            React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heat loss by element"),
                            React.createElement("div", null,
                                totalHeatLossWFromElements,
                                " W")),
                        React.createElement(ProgressChart, { total: totalHeatLossWFromElements, items: heatLossForChart }))),
                React.createElement(TableLite, { columns: conductionHeatLossColumns, rows: conductionHeatLossRowsCombinedForTable }),
                React.createElement(TableLite, { columns: ventilationHeatLossColumns, rows: ventilationHeatLossCombinedForTable }),
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: 'flex justify-between text-gray-900 font-bold px-2' },
                        React.createElement("div", null, "Total"),
                        React.createElement("div", null,
                            totalHeatLossWFromElements,
                            " W")),
                    survey.intermittent_heating && React.createElement("div", { className: 'text-xs text-gray-500 italic font-light' }, `* Intermittent heating correction factor of ${survey.intermittent_heating_oversize_factor_percentage}% applied`)))));
};
const MetropixFloorPlanInput = ({ inputRef, survey, setSurvey }) => {
    return React.createElement("input", { ref: inputRef, className: 'hidden', type: "file", accept: '*', onChange: (e) => {
            var _a, _b;
            const selectedFile = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
            if (!selectedFile)
                return;
            const fileReader = new FileReader();
            if (selectedFile.type === 'text/xml') {
                fileReader.onload = async (e) => {
                    var _a;
                    const floors = metropixImportFloorplan((_a = e.target) === null || _a === void 0 ? void 0 : _a.result, survey);
                    if (!floors) {
                        alert('Invalid Metropix file');
                        return;
                    }
                    floors.forEach(floor => {
                        setSurvey(prev => ({ ...prev, floors: [...prev.floors, floor] }));
                    });
                };
                fileReader.readAsText(selectedFile);
            }
            else {
                alert('Invalid file type');
            }
        } });
};
const MagicplanModal = ({ survey, setSurvey, showMagicplanModal, setShowMagicplanModal }) => {
    const adminContext = useContext(AdminContext);
    const [showFetchLoader, setShowFetchLoader] = useState(false);
    const [showImportLoader, setShowImportLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const handleLoadProjects = async () => {
        if (!adminContext)
            return;
        setShowFetchLoader(true);
        const projects = await getMagicplanPlans(adminContext.data.company.public_info.uuid);
        setProjects(projects);
        setShowFetchLoader(false);
    };
    const projectsItems = !showMagicplanModal ? [] : projects.map((p, idx) => {
        return {
            name: p.name,
            description: p.address.postal_code ? _.compact([p.address.street, p.address.street_number, p.address.city, p.address.postal_code]).join(', ') : undefined,
            onClick: () => setSelectedProject(p),
            variant: selectedProject === p ? 'ACTIVE' : 'DEFAULT',
            rightContent: p.thumbnail_url ? React.createElement("img", { className: 'w-20 h-20', src: p.thumbnail_url, onError: (e) => {
                    // if thumbnail fails to load, set it to empty src
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Empty GIF
                } }) : undefined
        };
    });
    const handleConfirmation = async () => {
        if (!selectedProject)
            return;
        setShowImportLoader(true);
        const planDetails = await getMagicplanPlanDetails(selectedProject.id, adminContext.data.company.public_info.uuid);
        if (!planDetails) {
            alert('Error getting plan details');
            return;
        }
        const floors = convertMagicplanPlanDetailsToFloors(planDetails, survey);
        if (!floors) {
            alert('Error converting plan details to floors');
            return;
        }
        setSurvey(prev => ({
            ...prev,
            floors: [
                // FIXME: uncomment: ...prev.floors,
                ...floors
            ]
        }));
        setShowImportLoader(false);
        setShowMagicplanModal(false);
    };
    useEffect(() => {
        handleLoadProjects();
    }, []);
    if (!showMagicplanModal)
        return null;
    return React.createElement(Modal, { visible: showMagicplanModal, setVisible: setShowMagicplanModal, title: 'Import Magicplan project', onConfirm: handleConfirmation, confirmButtonLabel: 'Import', confirmDisabled: !selectedProject || showImportLoader || showFetchLoader, thirdButton: undefined, hideOnConfirm: false },
        React.createElement("div", { className: 'w-full flex flex-col gap-2 h-96' },
            showImportLoader &&
                React.createElement("div", { className: 'flex flex-col justify-center items-center h-96 gap-4' },
                    React.createElement("span", null,
                        "Importing Magicplan project: ", selectedProject === null || selectedProject === void 0 ? void 0 :
                        selectedProject.name),
                    React.createElement(Loader, null)),
            !showImportLoader && React.createElement(React.Fragment, null,
                React.createElement(Button, { disabled: showFetchLoader, onClick: handleLoadProjects }, "Reload projects"),
                showFetchLoader && React.createElement(Loader, null),
                !showFetchLoader && projects.length !== 0 && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'h-96 overflow-y-scroll' },
                        React.createElement(RadioGroup, { items: projectsItems, isVertical: true })))),
            !showFetchLoader && projects.length === 0 && React.createElement(React.Fragment, null, "No projects found")));
};
