import { updateCompanySettings } from '../../../code/models/company';
import { Section } from '../../../components/containers/section';
import React from 'react';
import { SortableList } from '../components/sortable_list';
import { Button } from '../../../components/buttons/button';
export const SourcesBlock = ({ company, setCompany }) => {
    const defaultOptions = ['Social media', 'Search engine', 'Word of mouth', 'Trade show'];
    const resetToDefaults = async () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                sources: defaultOptions
            }
        };
        setCompany(newCompany);
        await updateCompanySettings(newCompany);
    };
    return React.createElement(Section, { border: true, title: "'Where did you hear about us?' options" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-sm text-gray-600' }, "Drag and drop using the handle on the left to reorder the options. Customers have the option to select from these source or choose 'Other' and give more details."),
            React.createElement(SortableList, { items: company.public_info.sources.map((name, id) => ({ name, id })), setItems: async (items) => {
                    // If the last item is deleted, fill the list with default options
                    if (items.length === 0) {
                        resetToDefaults();
                        return;
                    }
                    const newCompany = {
                        ...company,
                        public_info: {
                            ...company.public_info,
                            sources: items.map((item) => item.name)
                        }
                    };
                    setCompany(newCompany);
                    await updateCompanySettings(newCompany);
                } }),
            React.createElement(Button, { colour: "LIGHT", onClick: async () => await resetToDefaults() }, "Reset default options")));
};
