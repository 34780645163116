import { scaleBy, maxScale, minScale } from './constants';
export const onWheel = (e, stageScale, stagePosition, setScale, setStagePosition) => {
    e.evt.preventDefault();
    const stage = e.target.getStage();
    if (!stage)
        return;
    const pointer = stage.getPointerPosition();
    if (!pointer)
        return;
    const oldScale = stageScale;
    const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
    if (newScale >= maxScale || newScale <= minScale)
        return;
    const mousePointTo = {
        x: (pointer.x - stagePosition.x) / oldScale,
        y: (pointer.y - stagePosition.y) / oldScale
    };
    const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale
    };
    setScale(newScale);
    setStagePosition({ x: newPos.x, y: newPos.y });
};
