import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { Icon } from '../../../components/buttons/icon';
import { faCheck, faEdit, faGripLines, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Item = ({ item, handleEditItem, handleDeleteItem, isEditing, setIsEditing, editingText, setEditingText, dragDisabled }) => {
    const editingTextValid = editingText.trim().length > 0;
    return (React.createElement("div", { className: `${item.chosen ? 'bg-gray-100' : 'bg-white'} hover:bg-gray-50 py-2 px-4 border border-gray-200 flex items-center gap-2 first:rounded-t-md last:rounded-b-md mb-1` },
        React.createElement("span", { className: `handle ${dragDisabled && 'pointer-events-none'} pr-2 cursor-move` },
            React.createElement(Icon, { icon: faGripLines })),
        !isEditing && (React.createElement("span", { className: 'flex-grow select-none' }, item.name)),
        isEditing && (React.createElement(Input, { value: editingText, setValue: setEditingText, className: 'flex-grow' })),
        !isEditing && React.createElement(Button, { onClick: () => { setEditingText(item.name); setIsEditing(item.id); } },
            React.createElement(FontAwesomeIcon, { className: "w-3 h-3", icon: faEdit })),
        !isEditing && React.createElement(Button, { onClick: () => handleDeleteItem(item.id) },
            React.createElement(FontAwesomeIcon, { className: "w-3 h-3", icon: faTrashCan })),
        isEditing && React.createElement(Button, { disabled: !editingTextValid, onClick: () => handleEditItem(item.id) },
            React.createElement(FontAwesomeIcon, { "data-cy": 'table_save_button', className: "w-3 h-3", icon: faCheck }))));
};
export const SortableList = ({ items, setItems }) => {
    const [editingId, setEditingId] = useState(null);
    const [editingText, setEditingText] = useState('');
    const updateIds = (newItems) => {
        return newItems.map((item, index) => ({
            ...item,
            id: index + 1
        }));
    };
    const handleEditItem = (id) => {
        const newState = items.map((item) => {
            if (item.id === id) {
                return { ...item, name: editingText };
            }
            return item;
        });
        setItems(updateIds(newState));
        setEditingId(null);
    };
    const handleDeleteItem = (id) => {
        const newState = items.filter((item) => item.id !== id);
        setItems(updateIds(newState));
    };
    const handleAddItem = () => {
        const newState = [...items, { id: items.length + 1, name: 'New item' }];
        setItems(updateIds(newState));
    };
    if (!items.length) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'p-4 border border-gray-200 text-gray-500 text-sm text-center' }, "No options. Add some to get started."),
            React.createElement(Button, { onClick: () => handleAddItem() }, "Add option")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactSortable, { list: items, setList: (newState) => {
                setItems(updateIds(newState));
            }, handle: ".handle" }, items.map((item) => (React.createElement(Item, { dragDisabled: editingId !== null, key: item.id, item: item, handleEditItem: handleEditItem, handleDeleteItem: handleDeleteItem, isEditing: editingId === item.id, setIsEditing: setEditingId, editingText: editingText, setEditingText: setEditingText })))),
        React.createElement(Button, { onClick: () => handleAddItem() }, "Add option")));
};
