import React, { useState } from 'react';
import { Button } from '../../components/buttons/button';
import { Input } from '../../components/inputs_and_selections/input';
import { validateIsNumber, validateIsPositiveNumber, validateIsPositiveNumberOrZero } from '../../code/validators';
export const UValueForm = ({ closeCallback, ACH, internalTemp, designTemp, externalWallUValue, partyWallUValue, windowsUValue, floorUValue, roofUValue, colour, onSubmit, defaults }) => {
    const [editACHUValue, setEditACHUValue] = useState(ACH.toString());
    const [editInternalTemp, setEditInternalTemp] = useState(internalTemp.toString());
    const [editDesignTemp, setEditDesignTemp] = useState(designTemp.toString());
    const [editExternalWallUValue, setEditExternalWallUValue] = useState(externalWallUValue.toFixed(2));
    const [editPartyWallUValue, setEditPartyWallUValue] = useState(partyWallUValue.toFixed(2));
    const [editWindowsUValue, setEditWindowsUValue] = useState(windowsUValue.toFixed(2));
    const [editFloorUValue, setEditFloorUValue] = useState(floorUValue.toFixed(2));
    const [editRoofUValue, setEditRoofUValue] = useState(roofUValue.toFixed(2));
    const values = {
        internalTempOverride: validateIsPositiveNumber(editInternalTemp).value,
        designTempOverride: validateIsNumber(editDesignTemp).value,
        airChangeOverride: validateIsPositiveNumber(editACHUValue).value,
        externalWallUValueOverride: validateIsPositiveNumber(editExternalWallUValue).value,
        partyWallUValueOverride: validateIsPositiveNumberOrZero(editPartyWallUValue).value,
        windowsUValueOverride: validateIsPositiveNumberOrZero(editWindowsUValue).value,
        floorUValueOverride: validateIsPositiveNumberOrZero(editFloorUValue).value,
        roofUValueOverride: validateIsPositiveNumberOrZero(editRoofUValue).value
    };
    const allValid = Object.values(values).every(x => x !== undefined); // don't want to disable the button if the value is 0
    return React.createElement("div", { className: "space-y-2 text-sm" },
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Design temp."),
            React.createElement(Input, { validator: validateIsNumber, shrink: true, size: 'SM', type: "number", step: 0.1, postfix: '°C', value: editDesignTemp, setValue: setEditDesignTemp })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Internal temp."),
            React.createElement(Input, { validator: validateIsPositiveNumber, shrink: true, size: 'SM', type: "number", step: 0.1, postfix: '°C', value: editInternalTemp, setValue: setEditInternalTemp })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "ACH"),
            React.createElement(Input, { validator: validateIsPositiveNumber, shrink: true, size: 'SM', type: "number", step: 0.1, postfix: '/hr', value: editACHUValue, setValue: setEditACHUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "External Wall U-Value"),
            React.createElement(Input, { validator: validateIsPositiveNumberOrZero, shrink: true, size: 'SM', type: "number", step: 0.1, postfix: 'W/m²K', value: editExternalWallUValue, setValue: setEditExternalWallUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Party Wall U-Value"),
            React.createElement(Input, { validator: validateIsPositiveNumberOrZero, shrink: true, size: 'SM', type: "number", step: 0.1, postfix: 'W/m²K', value: editPartyWallUValue, setValue: setEditPartyWallUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Windows U-Value"),
            React.createElement(Input, { validator: validateIsPositiveNumberOrZero, shrink: true, size: 'SM', type: "number", step: 0.1, postfix: 'W/m²K', value: editWindowsUValue, setValue: setEditWindowsUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Floor U-Value"),
            React.createElement(Input, { validator: validateIsPositiveNumberOrZero, shrink: true, size: 'SM', type: "number", step: 0.1, postfix: 'W/m²K', value: editFloorUValue, setValue: setEditFloorUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Roof U-Value"),
            React.createElement(Input, { validator: validateIsPositiveNumberOrZero, shrink: true, size: 'SM', type: "number", step: 0.1, postfix: 'W/m²K', value: editRoofUValue, setValue: setEditRoofUValue })),
        React.createElement("div", { className: 'flex justify-between space-x-1' },
            React.createElement(Button, { onClick: () => {
                    setEditACHUValue(defaults.airChangesDefault.toString());
                    setEditExternalWallUValue(defaults.externalWallDefault.toString());
                    setEditPartyWallUValue(defaults.partyWallDefault.toString());
                    setEditWindowsUValue(defaults.windowDefault.toString());
                    setEditFloorUValue(defaults.floorDefault.toString());
                    setEditRoofUValue(defaults.roofDefault.toString());
                    setEditDesignTemp(defaults.designTempDefault.toString());
                    setEditInternalTemp(defaults.internalTempDefault.toString());
                } }, "Reset Defaults"),
            React.createElement("div", { className: "space-x-1 flex" },
                React.createElement(Button, { onClick: () => {
                        closeCallback();
                    } }, "Cancel"),
                React.createElement(Button, { disabled: !allValid, onClick: () => {
                        closeCallback();
                        onSubmit(values);
                    } }, "Save"))));
};
