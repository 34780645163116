import { axiosDeleteV2, axiosGetV2, axiosPatchV2, axiosPostV2 } from '../axios';
export const getCalculatedQuote = async (calculatedEstimateId, companyUUID) => await axiosGetV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`);
export const getCalculatedQuotes = async (leadUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/leads/${leadUUID}/calculated_estimates`);
export const insertCalculatedQuote = async (calculatedQuote, companyUUID) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates`, calculatedQuote);
};
export const updateCalculatedQuote = async (calculatedQuote, companyUUID) => {
    await axiosPatchV2(`teams/${companyUUID}/calculated_estimates/${calculatedQuote.uuid}`, calculatedQuote);
};
export const deleteCalculatedQuote = async (calculatedEstimateId, companyUUID) => { await axiosDeleteV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`); };
export const sendCallback = async (calculatedEstimateId, companyUUID, discussionContent, callTime) => await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}/callback`, { discussion_content: discussionContent, call_time: callTime });
export const sendSurvey = async (calculatedEstimate, companyUUID, emailHTML) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/survey`, { email_html: emailHTML });
    await updateCalculatedQuote({ ...calculatedEstimate, status: 'Accepted' }, companyUUID);
};
export const sendRejected = async (calculatedEstimate, companyUUID, reason) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/not_interested`, { reason });
    await updateCalculatedQuote({ ...calculatedEstimate, status: 'Rejected' }, companyUUID);
};
