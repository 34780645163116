import React from 'react';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
import { ClickableCard } from '../../../components/content_display/card';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { sum } from 'lodash';
import { combineHeatLossesForProgressChart, combineSortConductionHeatLosses, combineVentilationHeatLosses, getConductionHeatLossAllElements, getVentilationHeatLoss } from '../../../code/models/heat_loss';
import { MATERIAL_ELEMENT_NAMES } from '../../../code/models/material';
import { numberFormat } from '../../../code/number_format';
import { HLRListBlock } from './partials/hlr_list';
export const HLRHeatlossByElementPage = () => {
    const hlrContext = React.useContext(HLRContext);
    const conductionHeatLossRows = hlrContext.survey.floors.flatMap(floor => floor.rooms.flatMap(room => getConductionHeatLossAllElements(room, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.survey)));
    const ventilationHeatLosses = hlrContext.survey.floors.flatMap(floor => floor.rooms.map(room => getVentilationHeatLoss(room, hlrContext.designTempC, hlrContext.survey)));
    const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, ventilationHeatLosses);
    const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows, false)
        // add annualEnergy to each row
        .map(x => {
        const deltaT = x.roomTempC - hlrContext.designTempC;
        return { ...x, annualEnergy: (x.watts / 1000) / deltaT * hlrContext.degreeDays * 24 };
    });
    const ventilationHeatLossCombinedForTable = combineVentilationHeatLosses(ventilationHeatLosses)
        // add annualEnergy to each row
        .map(x => {
        const deltaT = x.roomTempC - hlrContext.designTempC;
        return { ...x, annualEnergy: (x.watts / 1000) / deltaT * hlrContext.degreeDays * 24 };
    });
    const totalHeatLossWFromElements = Math.round(sum([...conductionHeatLossRowsCombinedForTable, ...ventilationHeatLossCombinedForTable].map(x => x.watts)));
    const totalAnnualEnergyKWHFromElements = Math.round(sum([...conductionHeatLossRowsCombinedForTable, ...ventilationHeatLossCombinedForTable].map(x => x.annualEnergy)));
    // group by material element: element => [rows]
    const conductionGroupedByMaterialElement = conductionHeatLossRowsCombinedForTable.reduce((acc, row) => {
        const key = MATERIAL_ELEMENT_NAMES[row.material.applicable_to];
        acc[key] = acc[key] || [];
        acc[key].push(row);
        return acc;
    }, {});
    return (React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement(HLRSubheaderBLock, { section: "Heat loss", title: "Heat loss by element", description: "This section shows the heat loss through each element in your home. They give you a sense of which parts of your home fabric lose the most heat, and may indicate areas where insulating could have a significant impact on your heat loss." }),
        React.createElement(ClickableCard, { variant: 'GREY' },
            React.createElement("div", { className: "gap-3 flex justify-between" },
                React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Total heat loss"),
                React.createElement("div", { className: "text-lg" },
                    totalHeatLossWFromElements,
                    " W")),
            React.createElement(ProgressChart, { total: totalHeatLossWFromElements, items: heatLossForChart })),
        React.createElement("div", { className: 'grid grid-cols-5' },
            React.createElement("div", { className: 'border-b py-3 border-dashed border-gray-300' }),
            React.createElement("div", { className: "border-b py-3 border-dashed border-gray-300 text-gray-500 text-xs font-semibold uppercase tracking-wide text-right" }, "U-Value* (W/m\u00B2K)"),
            React.createElement("div", { className: "border-b py-3 border-dashed border-gray-300 text-gray-500 text-xs font-semibold uppercase tracking-wide text-right" }, "Area"),
            React.createElement("div", { className: "border-b py-3 border-dashed border-gray-300 text-gray-500 text-xs font-semibold uppercase tracking-wide text-right" }, "Heat loss"),
            React.createElement("div", { className: "border-b py-3 border-dashed border-gray-300 text-gray-500 text-xs font-semibold uppercase tracking-wide text-right" }, "Annual energy"),
            Object.entries(conductionGroupedByMaterialElement).map((entry, i) => {
                const border = i === conductionHeatLossRowsCombinedForTable.length - 1 ? '' : 'border-b border-dashed border-gray-300';
                const elementName = entry[0];
                const elements = entry[1];
                const areaM2 = sum(elements.map(x => x.areaM2));
                const watts = sum(elements.map(x => x.watts));
                const annualEnergy = sum(elements.map(x => x.annualEnergy));
                const uValueRange = elements.sort((e1, e2) => {
                    return e1.uValueWPerM2K - e2.uValueWPerM2K;
                }).map(x => x.uValueWPerM2K);
                const uValueMin = uValueRange[0];
                const uValueMax = uValueRange[uValueRange.length - 1];
                const uValueText = uValueMin === uValueMax ? numberFormat(2).format(uValueMin) : `${numberFormat(2).format(uValueMin)} — ${numberFormat(2).format(uValueMax)}`;
                // make unique material names
                const materialNamesUnique = Array.from(new Set(elements.map(x => x.material.name)));
                // provide a key for iterated block. The <>...</> structure does not accept the `key` attribute, so we're using the React.Fragment which is the same
                // without the `key` attribute is causes warnings in console and potentially may cause more serious behaviour issues
                return React.createElement(React.Fragment, { key: 'conduction-rows-' + i },
                    React.createElement("div", { className: `${border} py-3 flex-col flex` },
                        React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, elementName),
                        React.createElement(HLRListBlock, { items: materialNamesUnique.map((mn, materialIdx) => React.createElement(React.Fragment, null, mn)), isNumbered: true, className: 'text-gray-500 text-xs tracking-tight', gapSize: 1 })),
                    React.createElement("div", { className: `${border} py-3 text-right text-gray-600 text-xs font-semibold` }, uValueText),
                    React.createElement("div", { className: `${border} py-3 text-right text-gray-600 text-xs font-semibold` },
                        numberFormat(1).format(areaM2),
                        " m\u00B2"),
                    React.createElement("div", { className: `${border} py-3 text-right text-gray-600 text-xs font-semibold` },
                        numberFormat(0).format(watts),
                        " W"),
                    React.createElement("div", { className: `${border} py-3 text-right text-gray-600 text-xs font-semibold` },
                        numberFormat(0).format(annualEnergy),
                        " kWh"));
            }),
            React.createElement("div", { className: 'pt-6 pb-3 border-b border-dashed border-gray-300' }),
            React.createElement("div", { className: "text-gray-500 border-b border-dashed border-gray-300 pt-6 pb-3 text-xs font-semibold uppercase tracking-wide text-right" }, "ACH**"),
            React.createElement("div", { className: "text-gray-500 border-b border-dashed border-gray-300 pt-6 pb-3 text-xs font-semibold uppercase tracking-wide text-right" }, "Volume"),
            React.createElement("div", { className: "text-gray-500 border-b border-dashed border-gray-300 pt-6 pb-3 text-xs font-semibold uppercase tracking-wide text-right" }, "Heat loss"),
            React.createElement("div", { className: "text-gray-500 border-b border-dashed border-gray-300 pt-6 pb-3 text-xs font-semibold uppercase tracking-wide text-right" }, "Annual energy"),
            ventilationHeatLossCombinedForTable.map((col, idx) => {
                const border = 'border-b border-dashed border-gray-300';
                return React.createElement(React.Fragment, { key: 'ventilation-rows-' + idx },
                    React.createElement("div", { className: `${border} py-3 text-gray-900 text-xs font-bold` }, "Ventilation"),
                    React.createElement("div", { className: `${border} py-3 text-right text-gray-600 text-xs font-semibold` }, col.ACH),
                    React.createElement("div", { className: `${border} py-3 text-right text-gray-600 text-xs font-semibold` },
                        numberFormat(0).format(col.volumeM3),
                        " m\u00B3"),
                    React.createElement("div", { className: `${border} py-3 text-right text-gray-600 text-xs font-semibold` },
                        numberFormat(0).format(col.watts),
                        " W"),
                    React.createElement("div", { className: `${border} py-3 text-right text-gray-600 text-xs font-semibold` },
                        numberFormat(0).format(col.annualEnergy),
                        " kWh"));
            }),
            React.createElement("div", { className: 'py-3 text-gray-900 text-xs font-bold' }, "Total"),
            React.createElement("div", { className: "" }),
            React.createElement("div", { className: "" }),
            React.createElement("div", { className: "py-3 text-right text-gray-600 text-xs font-semibold" },
                numberFormat(0).format(totalHeatLossWFromElements),
                " W"),
            React.createElement("div", { className: "py-3 text-right text-gray-600 text-xs font-semibold" },
                numberFormat(0).format(totalAnnualEnergyKWHFromElements),
                " kWh")),
        React.createElement("div", { className: "flex-col flex" },
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "* U-Value: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "the sum of the thermal resistances of the layers that make up that element")),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "**ACH: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "air changes per hour")))));
};
