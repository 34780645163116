import { XMLParser } from 'fast-xml-parser';
import { getFloorAndCeilingDefaultMaterialsForFloor } from '../models/floor';
import { DEFAULT_SURVEY_FLOOR, DEFAULT_SURVEY_ROOM, DEFAULT_SURVEY_WALL } from '../survey_defaults';
import _ from 'lodash';
import { ROOM_TYPES } from '../../pages/heat_loss/constants';
export function metropixImportFloorplan(xmlString, survey) {
    const mFloorplan = parseMetropixXML(xmlString);
    if (!mFloorplan)
        return;
    try {
        return mFloorplan.floors.map(mFloor => convertMetropixFloorToFloor(mFloor, survey));
    }
    catch (e) {
        console.error('Error converting Metropix floor to Floor', e);
        return undefined;
    }
}
const convertMetropixFloorToFloor = (mFloor, survey) => {
    const floor = {
        ...DEFAULT_SURVEY_FLOOR,
        uuid: crypto.randomUUID()
        // mandatory fields
        // TODO: dry the code, generate default objects somewhere else in a single place
        // default_floor_material_type: floorMaterialType,
        // default_ceiling_material_type: ceilingMaterialType
    };
    const [floorMaterial, ceilingMaterial] = getFloorAndCeilingDefaultMaterialsForFloor(floor, survey);
    return {
        ...floor,
        name: 'Imported: ' + mFloor.name,
        rooms: mFloor.rooms.map(mRoom => {
            const defaultRoomType = ROOM_TYPES.find(x => x.uuid === 'living_lounge');
            const room = {
                ...DEFAULT_SURVEY_ROOM,
                uuid: crypto.randomUUID(),
                // mandatory fields
                // TODO: dry the code, generate default objects somewhere else in a single place
                room_type_uuid: defaultRoomType.uuid,
                floor_material: floorMaterial,
                ceiling_material: ceilingMaterial,
                age_band: survey.age_band
            };
            // Align walls vertices
            //
            // The problem: Metropix defines walls as edges between two vertices,
            // so our expectation is vertices to be aligned in the right order for each wall:
            // wall1: v1 -> v2
            // wall2: v2 -> v3
            // wall3: v3 -> v4
            // wall4: v4 -> v1
            //
            // but in reality, Metropix may define vertices in a different order:
            // wall1: v1 -> v2
            // wall2: v3 -> v2
            // wall3: v4 -> v3
            // wall4: v1 -> v4
            // which is the same, but vertices are not aligned in the right order.
            //
            // example set of wall's vertices: 10->6,12->10,12->11,6->11
            // step 1: skip the first wall
            // step 2: find a connection vertex to the previous wall (it's 10). Write it to the previous index.
            // step 3: repeat until the last wall (will be: 12, 11)
            // step 4: find a connection vertex to the first wall (it's 6)
            // the result set should be: 10, 12, 11, 6
            // define an empty array of vertices for each our wall
            const vertices = mRoom.walls.map(w => undefined);
            mRoom.walls.forEach((w, idx) => {
                // what wall index to match to the current wall
                // for the first wall match to the last wall
                // every next wall should match to the previous wall
                // so last wall should match to the -2 wall, update it, and cycle ends
                const matchingIdx = idx === 0 ? mRoom.walls.length - 1 : idx - 1;
                // the matching wall itself
                const matchingWall = mRoom.walls[matchingIdx];
                // running matching rules
                // there is only one match of four possible combinations
                if (matchingWall.v1.idx === w.v1.idx)
                    vertices[matchingIdx] = w.v1;
                else if (matchingWall.v1.idx === w.v2.idx)
                    vertices[matchingIdx] = w.v2;
                else if (matchingWall.v2.idx === w.v1.idx)
                    vertices[matchingIdx] = w.v1;
                else if (matchingWall.v2.idx === w.v2.idx)
                    vertices[matchingIdx] = w.v2;
                else
                    throw new Error('Wall vertices are not connected');
            });
            return {
                ...room,
                name: mRoom.name,
                height_m: mRoom.height,
                x: 0,
                y: 0,
                walls: mRoom.walls.map((w, idx) => {
                    const wall = {
                        ...DEFAULT_SURVEY_WALL,
                        uuid: crypto.randomUUID(),
                        // mandatory fields
                        // TODO: dry the code, generate default objects somewhere else in a single place
                        material: survey.default_materials.externalWall
                    };
                    return {
                        ...wall,
                        // get the right vertices from the properly aligned array above
                        x: cmToPoints(vertices[idx].x),
                        y: cmToPoints(vertices[idx].y)
                    };
                })
            };
        })
    };
};
const parseMetropixXML = (xmlString) => {
    const parser = new XMLParser({
        ignoreAttributes: false,
        parseAttributeValue: true,
        parseTagValue: true
    });
    try {
        const clsPlan = parser.parse(xmlString)['fme:xml-tables']['fme:Failed-table']['fme:Failed'].clsPlan;
        const mFloorplan = {
            floors: [],
            arrowDir: parseInt(clsPlan.arrowDir),
            areaDetails: {
                showArea: clsPlan.areaDetails.showArea,
                manualArea: parseFloat(clsPlan.areaDetails.manualArea),
                manualAreaMetric: clsPlan.areaDetails.manualAreaMetric === 'true'
            },
            moreOptions: {
                overrideLayout: clsPlan.moreOptions.overrideLayout === 'true',
                layout: clsPlan.moreOptions.layout,
                outlineLabel: clsPlan.moreOptions.outlineLabel,
                plotLabel: clsPlan.moreOptions.plotLabel
            },
            drawTime: parseInt(clsPlan.drawTime),
            multiplePiecesWarned: clsPlan.multiplePiecesWarned === 'true'
        };
        // Parse floors
        const xmlFloors = Array.isArray(clsPlan.f.planFloor) ? clsPlan.f.planFloor : [clsPlan.f.planFloor];
        xmlFloors.forEach((xmlFloor) => {
            const mFloor = {
                name: xmlFloor.name,
                outerWallThickness: parseFloat(xmlFloor.outerWallThickness),
                vertices: [],
                walls: [],
                rooms: []
            };
            // Parse vertices
            xmlFloor.planVerts.planVert.forEach((xmlVert, idx) => {
                // skip if xsi:nil="true"
                if (xmlVert['@_xsi:nil'] === true) {
                    return;
                }
                mFloor.vertices.push({
                    idx,
                    groupId: parseInt(xmlVert.groupId),
                    x: cmToPoints(parseFloat(xmlVert.x)),
                    y: cmToPoints(parseFloat(xmlVert.y)),
                    deleted: xmlVert.deleted === 'true',
                    visible: xmlVert.visible === 'true'
                });
            });
            // Parse walls
            xmlFloor.planWalls.planWall.forEach((xmlWall, idx) => {
                const v1 = xmlWall.v1idx ? mFloor.vertices[parseInt(xmlWall.v1idx)] : undefined;
                const v2 = xmlWall.v2idx ? mFloor.vertices[parseInt(xmlWall.v2idx)] : undefined;
                mFloor.walls.push({
                    idx,
                    v1,
                    v2,
                    thickness: xmlWall.thickness ? parseFloat(xmlWall.thickness) : undefined
                    // TODO: parts: wall.parts.planPart || []
                    //  add door and window parsing
                });
            });
            // Parse rooms
            const rooms = Array.isArray(xmlFloor.planRooms.planRoom)
                ? xmlFloor.planRooms.planRoom
                : [xmlFloor.planRooms.planRoom];
            rooms.forEach((xmlRoom, idx) => {
                // skip if xsi:nil="true"
                if (xmlRoom['@_xsi:nil'] === true) {
                    return;
                }
                const xmlRoomWallsIdxs = Array.isArray(xmlRoom.wallIdx.int)
                    ? xmlRoom.wallIdx.int.map((idx) => parseInt(idx))
                    : [parseInt(xmlRoom.wallIdx.int)];
                mFloor.rooms.push({
                    idx,
                    name: xmlRoom.name,
                    type: xmlRoom.type,
                    height: parseFloat(xmlRoom.height),
                    walls: _.compact(xmlRoomWallsIdxs.map(wallIdx => {
                        const wall = mFloor.walls[wallIdx];
                        // if wall tag may contain xsi:nil="true"
                        // we just skip such walls
                        return wall.v1 === undefined ? undefined : wall;
                    })),
                    area: xmlRoom.lenX !== '0' ? parseFloat(xmlRoom.lenX) * parseFloat(xmlRoom.lenY) : undefined
                });
            });
            mFloorplan.floors.push(mFloor);
        });
        return mFloorplan;
    }
    catch (e) {
        console.error('Error parsing Metropix XML', e);
        return undefined;
    }
};
const cmToPoints = (cm) => {
    // 50 points per 100 cm
    return cm; // * 50 / 100
};
