import React, { useEffect, useRef, useState } from 'react';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { getFloorAreaM2, getRoomWatts } from '../../../code/models/heat_loss';
import Konva from 'konva';
import { MATERIAL_ELEMENT_NAMES } from '../../../code/models/material';
import { MaterialsSelector } from '../materials/materials_selector';
import { Icon } from '../../../components/buttons/icon';
import { DesignPageHeader } from '../design/components/design_page_header';
import { TabGroup } from '../../../components/content_display/tab';
import { getRoomsWithMetaData } from './code/get_rooms_with_meta_data';
import { FloorStage } from './floor_stage';
import { calculateNewTouchingWalls, centreAndScaleOnFloor, centreOnRoomScaleOnFloor, fixRotation, getNextWall, removeInvalidRoomGroups, simplifyRooms, updateLength } from './code/utils';
import { findBestCreateRoomPosition } from './code/find_best_create_room_position';
import { onStageTouchMove } from './code/on_stage_touch_move';
import { onWheel } from './code/on_wheel';
import { ArrowUturnLeftIcon, ArrowUturnRightIcon, ViewfinderCircleIcon } from '@heroicons/react/16/solid';
import { Button } from '../../../components/buttons/button';
import { RoomHeatLossPage } from './room_heat_loss';
import { EntityToolbar } from './entity_toolbar';
import { WallPage } from '../wall';
import { RoomPage } from '../room';
import { AddFloor } from './add_floor';
import { AddRoomTab } from '../add_room';
import { FloorDetails } from './floor_details';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { CustomMaterialPopup } from '../materials/custom_material_popup';
import { EmptyState } from '../../../components/content_display/empty_state';
import { faChevronLeft, faCross, faList, faPlus, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FloorPlanInput } from './floor_buttons';
import { ScalePage } from './scale_page';
import { stageOnMouseMove } from './code/stage_on_mouse_move';
import { stageOnClick } from './code/stage_on_click';
import { Alert } from '../../../components/indicators_and_messaging/alert';
import { DEFAULT_IMAGE_SCALE } from './code/constants';
Konva.hitOnDragEnabled = true;
const WALL_PAGES = ['WALL_LENGTH', 'WALL_MATERIALS', 'WALL_WINDOWS', 'WALL_DOORS', 'REMOVE_WALL', 'WINDOW_PAGE', 'DOOR_PAGE'];
const ROOM_PAGES = ['ROOM_DETAILS', 'ROOM_FLOOR', 'ROOM_CEILING', 'ROOM_EMITTERS', 'CHOOSE_EMITTER', 'ADD_CUSTOM_RADIATOR', 'ROOM_PHOTOS', 'SLOPED_CEILING', 'ROOFLIGHT', 'RADIATOR', 'RADIATOR_MODEL', 'UNDERFLOOR', 'MANIFOLD', 'SECONDARY_HEATING'];
const FLOOR_PAGES = ['CHANGE_FLOOR', 'ADD_ROOM', 'FLOOR_DETAILS', 'ADD_FLOOR'];
const FLOOR_PLAN_PAGES = [...ROOM_PAGES, ...FLOOR_PAGES, ...WALL_PAGES, 'MATERIALS', 'CUSTOM_MATERIAL'];
const ROOM_TABS = ['Dimensions', 'Heat loss'];
export const FloorPage = ({ files, setFiles, floors, floor, setFloor, survey, design, setCurrentFloorId, removeFloor, designTempC, groundTempC, materials, setMaterials, materialsLayers, setSurvey, setSurveyTab, customRadiatorModels, setCustomRadiatorModels, allRadiatorModels, currentRoomId, currentFloorId, setCurrentRoomId, stagePosition, setStagePosition, stageScale, setStageScale, setFlowTemp, minFlowTemp, maxFlowTemp, stageRef, stageSize, setStageSize, companyUuid }) => {
    var _a, _b;
    const [currentWallId, setCurrentWallId] = useState();
    const [window, setWindow] = useState();
    const [door, setDoor] = useState();
    const [tempCustomMaterial, setTempCustomMaterial] = useState();
    const [currentRooflight, setCurrentRooflight] = useState();
    const [dragStopped, setDragStopped] = useState(false);
    const [lastCenter, setLastCenter] = useState();
    const [lastDist, setLastDist] = useState();
    const [drawingRooms, setDrawingRooms] = useState(false);
    const [scalingWindow, setScalingWindow] = useState(false);
    const [pages, setPages] = useState(['FLOOR_DETAILS']);
    const [isPageOpen, setIsPageOpen] = useState(false);
    const [showFloorPlanButtons, setShowFloorPlanButtons] = useState(false);
    const [guidelines, setGuidelines] = useState([]);
    const page = pages[pages.length - 1];
    // The page is always open on large screens but is dynamic on mobile size.
    // If I click a room in the canvas set the page to ROOM_DETAILS, however isPageOpen remains false.
    // If I click the canvas buttons, also set the page component to open for both desktop and mobile.
    const setPage = (page, open = false) => {
        setPages(prev => [...prev, page]);
        const closePage = page === 'FLOOR_DETAILS' && !open;
        const newIsPageOpen = !closePage && (open || isPageOpen);
        setIsPageOpen(newIsPageOpen);
    };
    const goBack = () => {
        setPages(prev => {
            const newPages = prev.slice(0, -1);
            if (newPages[newPages.length - 1] === 'FLOOR_DETAILS')
                setIsPageOpen(false);
            return prev[prev.length - 1] === 'FLOOR_DETAILS' ? prev : newPages;
        });
    };
    const [roomTab, setRoomTab] = useState('Dimensions');
    const [scalingPoints, setScalingPoints] = useState([]);
    const [mousePosition, setMousePosition] = useState();
    const [scaleLength, setScaleLength] = useState();
    const [tempImageAndScale, setTempImageAndScale] = useState({ image: '', scale: 10 });
    const [undoEvents, setUndoEvents] = useState([]);
    const [undoIndex, setUndoIndex] = useState(0);
    const [header, setHeader] = useState();
    const [intersectingShapes, setIntersectingShapes] = useState([]);
    const addEvent = (events) => {
        setUndoEvents(prev => [...prev.slice(0, undoIndex + (events.length - 1)), ...events]);
        for (const event of events) {
            redo(event);
        }
    };
    const undo = () => {
        const lastAction = undoEvents[undoIndex - 1];
        if (lastAction.action === 'ADD') {
            if (lastAction.type === 'ROOM')
                removeRoom(lastAction.newValue.uuid);
        }
        else if (lastAction.action === 'UPDATE') {
            if (lastAction.type === 'FLOOR')
                setFloor(lastAction.oldValue);
            else if (lastAction.type === 'ROOM')
                setRoom(lastAction.oldValue);
        }
        else if (lastAction.action === 'DELETE') {
            if (lastAction.type === 'ROOM')
                setFloor({ ...floor, rooms: [...floor.rooms, lastAction.oldValue] });
        }
        setUndoIndex(prev => prev - 1);
    };
    const redo = (event) => {
        if (event.action === 'ADD') {
            if (event.type === 'ROOM')
                setFloor({ ...floor, rooms: [...floor.rooms, event.newValue] });
        }
        else if (event.action === 'UPDATE') {
            if (event.type === 'FLOOR')
                setFloor(event.newValue);
            else if (event.type === 'ROOM')
                setRoom(event.newValue);
        }
        else if (event.action === 'DELETE') {
            if (event.type === 'ROOM')
                removeRoom(event.oldValue.uuid);
        }
        setUndoIndex(prev => prev + 1);
    };
    // We render the same component regardless of floor, so we need to reset these values when we change floor ID.
    useEffect(() => {
        setUndoEvents([]);
        setUndoIndex(0);
    }, [currentFloorId]);
    // We want to show the user the add room page if it is their first room on the floor.
    // When the page loads, we need to set the canvas size to its container so we can size floorplan correctly.
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        // We must do this here because we do not know what size the canvas will be when we click on a floor or a room.
        if (currentRoom)
            centreOnRoomScaleOnFloor(currentRoom, floor, setStagePosition, setStageScale, (_b = (_a = stageRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0, (_d = (_c = stageRef.current) === null || _c === void 0 ? void 0 : _c.clientHeight) !== null && _d !== void 0 ? _d : 0);
        else if (floor.rooms.length > 0)
            centreAndScaleOnFloor(floor, setStagePosition, setStageScale, (_f = (_e = stageRef.current) === null || _e === void 0 ? void 0 : _e.clientWidth) !== null && _f !== void 0 ? _f : 0, (_h = (_g = stageRef.current) === null || _g === void 0 ? void 0 : _g.clientHeight) !== null && _h !== void 0 ? _h : 0);
    }, []);
    const resetFloorPlan = () => {
        setScalingPoints([]);
        setMousePosition(undefined);
        setScaleLength(undefined);
        setTempImageAndScale({ image: '', scale: DEFAULT_IMAGE_SCALE });
        setStageStep(undefined);
    };
    const roomsWithMetaData = getRoomsWithMetaData(survey, floor, designTempC, groundTempC, design, stageScale);
    const wallsWithoutDuplicatesReduce = (prev, curr) => {
        if (!prev.some(x => x.x1 === curr.x1 && x.x2 === curr.x2 && x.y1 === curr.y1 && x.y2 === curr.y2))
            return [...prev, curr];
        return prev;
    };
    const wallsWithoutDuplicates = floor.rooms
        .flatMap(r => r.walls.filter(x => x.other_room_uuid)
        .map(w => {
        const nextWall = getNextWall(w, r.walls);
        const line = { x1: w.x + r.x, x2: nextWall.x + r.x, y1: w.y + r.y, y2: nextWall.y + r.y };
        const lineRotated = fixRotation(line.x1, line.x2, line.y1, line.y2);
        return { ...lineRotated, uuid: w.uuid };
    }))
        .reduce(wallsWithoutDuplicatesReduce, []);
    const currentRoom = floor.rooms.find(x => x.uuid === currentRoomId);
    const currentWall = currentRoom === null || currentRoom === void 0 ? void 0 : currentRoom.walls.find(x => x.uuid === currentWallId);
    const currentWallIndex = currentRoom === null || currentRoom === void 0 ? void 0 : currentRoom.walls.findIndex(x => x.uuid === (currentWall === null || currentWall === void 0 ? void 0 : currentWall.uuid));
    const nextWallIndex = currentWallIndex !== undefined && currentRoom ? (currentRoom.walls.length - 1 === currentWallIndex ? 0 : currentWallIndex + 1) : undefined;
    const linkedWall = nextWallIndex !== undefined ? currentRoom === null || currentRoom === void 0 ? void 0 : currentRoom.walls[nextWallIndex] : undefined;
    const pageTitle = currentWall ? (_a = MATERIAL_ELEMENT_NAMES[currentWall.material.applicable_to]) !== null && _a !== void 0 ? _a : 'Wall' : currentRoomId ? currentRoom === null || currentRoom === void 0 ? void 0 : currentRoom.name : floor.name;
    const setWalls = (walls) => {
        const newRoom = { ...currentRoom, walls };
        setFloor({ ...floor, rooms: floor.rooms.map(x => x.uuid === newRoom.uuid ? newRoom : x) });
    };
    const setRoom = (room) => {
        setFloor({
            ...floor,
            rooms: floor.rooms.map(x => x.uuid === room.uuid ? room : x)
        });
    };
    const setWall = (wall) => {
        const newWalls = currentRoom === null || currentRoom === void 0 ? void 0 : currentRoom.walls.map(x => x.uuid === wall.uuid ? wall : x);
        setFloor({
            ...floor,
            rooms: floor.rooms.map(x => x.uuid === currentRoom.uuid ? { ...currentRoom, walls: newWalls } : x)
        });
    };
    const removeRoom = (id) => {
        const newRooms = calculateNewTouchingWalls(floor.rooms.filter(x => x.uuid !== id), survey.default_materials);
        setFloor({ ...floor, rooms: newRooms });
        setCurrentRoomId(undefined);
        setCurrentWallId(undefined);
        goBack();
    };
    const saveWall = (wall, length) => {
        let newWalls = currentRoom.walls;
        if (length > 0) {
            newWalls = updateLength(currentRoom, currentRoom.walls.findIndex(x => x.uuid === wall.uuid), length);
        }
        const newRooms = floor.rooms.map(x => x.uuid === (currentRoom === null || currentRoom === void 0 ? void 0 : currentRoom.uuid) ? ({ ...currentRoom, walls: newWalls }) : x);
        const simplifiedRooms = simplifyRooms(newRooms);
        const calculatedNewRooms = calculateNewTouchingWalls(simplifiedRooms, survey.default_materials);
        const removedInvalidRoomGroups = removeInvalidRoomGroups(calculatedNewRooms);
        const events = [{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, rooms: removedInvalidRoomGroups } }];
        addEvent(events);
    };
    const centerX = (((stageSize.width / 2) - (stagePosition.x)) / stageScale);
    const centerY = (((stageSize.height / 2) - (stagePosition.y)) / stageScale);
    const { roomCenterX, roomCenterY } = findBestCreateRoomPosition(centerX, centerY, floor.rooms);
    const [msProps, setMsProps] = useState();
    const [stageStep, setStageStep] = useState(undefined);
    const steps = [
        { step: 1, primaryText: 'Find a line you know the measurement of and select the start point.', button: React.createElement(Button, { iconLeft: faCross }, "Cancel") },
        { step: 2, primaryText: 'Great! Now select the end point.', button: React.createElement(Button, { iconLeft: faChevronLeft }, "Back") },
        { step: 3, primaryText: 'Finally, enter the length of the object.', button: React.createElement(Button, { iconLeft: faChevronLeft }, "Back") }
    ];
    const showUploadWidthPage = stageStep === steps.length;
    const pageSelector = React.createElement(PageSelector, { currentRoom: currentRoom, currentWall: currentWall, linkedWall: linkedWall, floor: floor, setFloor: setFloor, setWall: setWall, page: page, setPage: setPage, setCurrentWallId: setCurrentWallId, survey: survey, saveWall: saveWall, materials: materials, msProps: msProps, setMsProps: setMsProps, addEvent: addEvent, design: design, setRoom: setRoom, files: files, setFiles: setFiles, setSurvey: setSurvey, designTempC: designTempC, groundTempC: groundTempC, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, floors: floors, setCurrentFloorId: setCurrentFloorId, roomCenterX: roomCenterX, roomCenterY: roomCenterY, setCurrentRoomId: setCurrentRoomId, scaleLength: scaleLength, setScaleLength: setScaleLength, tempImageAndScale: tempImageAndScale, setTempImageAndScale: setTempImageAndScale, scalingPoints: scalingPoints, mousePosition: mousePosition, setScalingPoints: setScalingPoints, materialsLayers: materialsLayers, setMaterials: setMaterials, setHeader: setHeader, window: window, setWindow: setWindow, door: door, setDoor: setDoor, currentRooflight: currentRooflight, setCurrentRooflight: setCurrentRooflight, tempCustomMaterial: tempCustomMaterial, setTempCustomMaterial: setTempCustomMaterial, customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, allRadiatorModels: allRadiatorModels, companyUuid: companyUuid, showUploadWidthPage: showUploadWidthPage, resetUploadPlan: resetFloorPlan, setStageStep: setStageStep, setScalingWindow: setScalingWindow, onBack: goBack, setPageOpen: setIsPageOpen });
    const fileInputRef = useRef(null);
    return React.createElement("div", { className: 'flex flex-col h-full' },
        React.createElement(DesignPageHeader
        // eslint-disable-next-line
        , { 
            // eslint-disable-next-line
            title: pageTitle, onBack: () => {
                if (currentRoomId) {
                    setCurrentRoomId(undefined); // go back to the floor level from the room
                    setRoomTab('Dimensions'); //   Also reset the room tab to dimensions so if you come back to the room you get the dimensions
                }
                else {
                    setSurveyTab('FLOOR_LIST'); // go back to the floor list from the floor
                    setCurrentFloorId(undefined);
                }
            }, rightHandChildren: !currentWall && roomTab !== 'Heat loss' &&
                React.createElement(Icon, { confirmTextHeader: currentRoomId ? ((_b = `Delete ${currentRoom === null || currentRoom === void 0 ? void 0 : currentRoom.name}`) !== null && _b !== void 0 ? _b : 'room') : `Delete ${floor.name}?`, icon: faTrashCan, onClick: () => currentRoomId ? addEvent([{ type: 'ROOM', action: 'DELETE', oldValue: currentRoom }]) : removeFloor(floor.uuid) }) }, currentRoom && !currentWall && React.createElement(TabGroup, { items: ROOM_TABS.map(x => ({
                name: x,
                secondaryText: x === 'Heat loss'
                    ? `${Math.round(getRoomWatts(currentRoom, floor.rooms, designTempC, groundTempC, survey))} W`
                    : `${getFloorAreaM2(currentRoom.walls).toFixed(1)} m²`,
                onClick: () => setRoomTab(x),
                variant: x === roomTab ? 'ACTIVE' : 'DEFAULT'
            })) })),
        React.createElement("div", { className: 'flex flex-grow h-0' },
            (floor.rooms.length === 0 && !stageStep && page === 'FLOOR_DETAILS' && !floor.floor_plan_image && !floor.floor_plan_url) && React.createElement("div", { className: 'bg-gray-50 flex items-center justify-center w-full' },
                React.createElement(EmptyState, { icon: faList, primaryText: 'No rooms', secondaryText: 'Upload a floor plan to trace, or Add a room to begin mapping your customer\'s home.', primaryButton: React.createElement(Button, { onClick: () => setPage('ADD_ROOM'), iconLeft: faPlus }, "Add room"), secondaryButton: React.createElement(Button, { onClick: () => { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, iconLeft: faUpload }, "Upload floor plan") }),
                React.createElement(FloorPlanInput, { inputRef: fileInputRef, setTempImageAndScale: setTempImageAndScale, setStageStep: setStageStep })),
            currentRoom && roomTab === 'Heat loss' &&
                React.createElement(RoomHeatLossPage, { room: currentRoom, roomsThisFloor: floor.rooms, designTempC: designTempC, groundTempC: groundTempC, flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, deltaTFlowReturnC: design.delta_t_flow_return_c, survey: survey, design: design }),
            roomTab !== 'Heat loss' && (floor.rooms.length > 0 || stageStep || page !== 'FLOOR_DETAILS' || floor.floor_plan_image || floor.floor_plan_url) && React.createElement(React.Fragment, null,
                React.createElement("div", { ref: stageRef, className: 'relative flex-grow' },
                    React.createElement("div", { className: 'absolute left-0 right-0 top-0 z-10 m-4 flex flex-col gap-4' },
                        stageStep && React.createElement(UploadPlanTooltip, { step: stageStep, setStep: setStageStep, setScalingPoints: setScalingPoints, resetFloorPlan: resetFloorPlan, primaryText: steps[stageStep - 1].primaryText }),
                        intersectingShapes.length > 0 && React.createElement(Alert, { type: 'DANGER' },
                            "You have ",
                            React.createElement("span", { className: 'font-bold' }, intersectingShapes.length / 2),
                            " rooms overlapping, you cannot continue until this is resolved. Locate the overlapping rooms (highlighted in red) and drag them apart."),
                        !stageStep && React.createElement("div", { className: 'flex justify-between gap-2' },
                            React.createElement("div", { className: 'flex gap-2' }, !drawingRooms && React.createElement(React.Fragment, null,
                                React.createElement(Button, { size: 'SM', colour: 'LIGHT', className: 'shadow', disabled: undoIndex === 0, onClick: undo },
                                    React.createElement("div", { className: "flex justify-center gap-1" },
                                        React.createElement(ArrowUturnLeftIcon, { className: "h-4 w-4" }),
                                        React.createElement("div", null, "Undo"))),
                                React.createElement(Button, { size: 'SM', colour: 'LIGHT', className: 'shadow', disabled: undoIndex === undoEvents.length, onClick: () => redo(undoEvents[undoIndex]) },
                                    React.createElement("div", { className: "flex justify-center gap-1" },
                                        React.createElement(ArrowUturnRightIcon, { className: "h-4 w-4" }),
                                        React.createElement("div", null, "Redo"))))),
                            React.createElement(Button, { size: 'SM', colour: 'LIGHT', onClick: () => {
                                    if (currentRoom) {
                                        centreOnRoomScaleOnFloor(currentRoom, floor, setStagePosition, setStageScale, stageSize.width, stageSize.height);
                                        return;
                                    }
                                    if (floor.rooms.length > 0) {
                                        centreAndScaleOnFloor(floor, setStagePosition, setStageScale, stageSize.width, stageSize.height);
                                    }
                                    else {
                                        setStageScale(1);
                                        setStagePosition({ x: stageSize.width / 2, y: stageSize.height / 2 });
                                    }
                                } },
                                React.createElement("div", { className: "flex justify-center gap-1" },
                                    React.createElement(ViewfinderCircleIcon, { className: "h-4 w-4" }),
                                    React.createElement("div", null, "Re-centre"))))),
                    !stageStep && React.createElement("div", { className: 'absolute bottom-0 left-1/2 m-4 z-10 transform -translate-x-1/2' },
                        React.createElement(EntityToolbar, { setTempImageAndScale: setTempImageAndScale, setPage: setPage, currentWall: currentWall, currentRoom: currentRoom, currentFloor: floor, setStageStep: setStageStep, showFloorPlanButtons: showFloorPlanButtons, setIsPageOpen: setIsPageOpen, setShowFloorPlanButtons: setShowFloorPlanButtons, setFloor: setFloor, drawingRooms: drawingRooms, setDrawingRooms: setDrawingRooms, cancelDrawingRooms: () => {
                                setDrawingRooms(false);
                                setScalingPoints([]);
                                setMousePosition(undefined);
                            }, scalingPoints: scalingPoints, setScalingPoints: setScalingPoints, intersectingShapes: intersectingShapes })),
                    React.createElement(FloorStage, { survey: survey, stageScale: stageScale, stagePosition: stagePosition, stageSize: stageSize, stageRef: stageRef, setStageSize: setStageSize, defaultMaterials: survey.default_materials, roomsWithMetaData: roomsWithMetaData, currentRoom: currentRoom, currentRoomId: currentRoomId, setCurrentRoomId: setCurrentRoomId, wallsWithoutDuplicates: wallsWithoutDuplicates, floor: floor, setFloor: setFloor, dragStopped: dragStopped, currentWall: currentWall, setStageScale: setStageScale, page: page, setPage: setPage, setCurrentWallId: setCurrentWallId, setWalls: setWalls, setStagePosition: setStagePosition, addEvent: addEvent, scalingPoints: scalingPoints, mousePosition: mousePosition, tempImageAndScale: tempImageAndScale, stageStep: stageStep, isDrawing: drawingRooms, onMouseMove: (e) => stageOnMouseMove(e, scalingPoints, drawingRooms, scalingWindow, floor, setMousePosition, stageStep, setGuidelines, stageScale, survey), onClick: (e) => stageOnClick(e, drawingRooms, mousePosition, scalingWindow, scalingPoints, floor, survey, stageStep, showUploadWidthPage, setScalingPoints, setCurrentRoomId, setStageStep, setPage, setCurrentWallId, setDrawingRooms, setMousePosition, addEvent, setScalingWindow, setWindow), onTouchEnd: () => {
                            setLastCenter(undefined);
                            setLastDist(0);
                        }, onDragMove: (e) => {
                            if (e.currentTarget === e.target) {
                                const newPos = e.currentTarget.position();
                                e.currentTarget.position(({ x: stagePosition.x, y: stagePosition.y }));
                                setStagePosition(newPos);
                            }
                        }, onWheelProp: (e) => onWheel(e, stageScale, stagePosition, setStageScale, setStagePosition), onTouchMove: (e) => onStageTouchMove(e, dragStopped, setDragStopped, lastCenter, setLastCenter, lastDist, setLastDist, stagePosition, setStagePosition, stageScale, setStageScale), intersectingShapes: intersectingShapes, scalingWindow: scalingWindow, guidelines: guidelines, setGuidelines: setGuidelines, setIntersectingShapes: setIntersectingShapes })),
                roomTab !== 'Heat loss' && !scalingWindow && React.createElement("div", { className: 'w-96 bg-white border-l border-gray-300 shadow-lg z-10 hidden md:flex flex-col' },
                    React.createElement("div", { className: 'px-5 py-3 border-b border-gray-300' }, header),
                    React.createElement("div", { className: 'overflow-y-auto' }, pageSelector))),
            React.createElement(BottomSheet, { header: header, initialFocusRef: false, scrollLocking: false, className: 'w-full md:hidden', open: roomTab !== 'Heat loss' && !scalingWindow && isPageOpen, onDismiss: () => {
                    if (showUploadWidthPage) {
                        setStageStep(prev => prev - 1);
                        setScalingPoints(prev => prev.slice(0, -1));
                    }
                    else {
                        setPage('FLOOR_DETAILS');
                    }
                } }, pageSelector)));
};
const PageSelector = ({ currentRoom, currentWall, linkedWall, floor, setFloor, setWall, page, setPage, setCurrentWallId, survey, saveWall, materials, msProps, setMsProps, addEvent, design, setRoom, files, setFiles, setSurvey, designTempC, groundTempC, setFlowTemp, minFlowTemp, maxFlowTemp, floors, setCurrentFloorId, roomCenterX, roomCenterY, setCurrentRoomId, scalingPoints, scaleLength, setScaleLength, tempImageAndScale, materialsLayers, setMaterials, setHeader, window, setWindow, door, setDoor, currentRooflight, setCurrentRooflight, tempCustomMaterial, setTempCustomMaterial, customRadiatorModels, setCustomRadiatorModels, allRadiatorModels, companyUuid, showUploadWidthPage, resetUploadPlan, setStageStep, setScalingPoints, setScalingWindow, onBack, setPageOpen }) => {
    if (currentRoom && currentWall && linkedWall && WALL_PAGES.includes(page)) {
        return React.createElement(WallPage, { onBack: onBack, currentRoom: currentRoom, floor: floor, setFloor: setFloor, wall: currentWall, setWall: setWall, page: page, setPage: setPage, linkedWall: linkedWall, setCurrentWallId: setCurrentWallId, survey: survey, onSave: saveWall, materials: materials, setMsProps: setMsProps, addEvent: addEvent, defaultMaterials: survey.default_materials, setHeader: setHeader, window: window, setWindow: setWindow, door: door, setDoor: setDoor, setScalingWindow: setScalingWindow, setStageStep: setStageStep });
    }
    if (ROOM_PAGES.includes(page) && currentRoom) {
        return React.createElement(RoomPage, { design: design, page: page, setPage: setPage, room: currentRoom, setRoom: setRoom, files: files, setFiles: setFiles, survey: survey, setSurvey: setSurvey, materials: materials, setMsProps: setMsProps, floor: floor, customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, allRadiatorModels: allRadiatorModels, designTempC: designTempC, groundTempC: groundTempC, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, setHeader: setHeader, currentRooflight: currentRooflight, setCurrentRooflight: setCurrentRooflight, companyUuid: companyUuid, onBack: onBack });
    }
    if (page === 'ADD_FLOOR') {
        return React.createElement(AddFloor, { setPage: setPage, floors: floors, setCurrentFloorId: setCurrentFloorId, setSurvey: setSurvey, onBack: onBack });
    }
    if (page === 'ADD_ROOM') {
        return React.createElement(AddRoomTab, { setFloor: setFloor, startingX: roomCenterX, startingY: roomCenterY, survey: survey, floor: floor, setPage: setPage, addEvent: addEvent, setCurrentRoomId: setCurrentRoomId, setHeader: setHeader, onBack: onBack, setPageOpen: setPageOpen });
    }
    if (showUploadWidthPage) {
        return React.createElement(ScalePage, { scalingPoints: scalingPoints, scaleLength: scaleLength, setScaleLength: setScaleLength, floor: floor, tempImageAndScale: tempImageAndScale, addEvent: addEvent, resetUploadPlan: resetUploadPlan, setStageStep: setStageStep, setHeader: setHeader, setScalingPoints: setScalingPoints });
    }
    if (page === 'MATERIALS') {
        return React.createElement(MaterialsSelector, { groupedProps: msProps, setGroupedProps: setMsProps, setMaterialsCallback: setMaterials, setPage: setPage, setHeader: setHeader, setTempCustomMaterial: setTempCustomMaterial, companyUUID: companyUuid, onBack: onBack });
    }
    if (page === 'CUSTOM_MATERIAL') {
        return React.createElement(CustomMaterialPopup, { material: tempCustomMaterial, setMaterial: setTempCustomMaterial, materialsLayers: materialsLayers, onSave: (newMaterial) => {
                // NB! do not override materials with the `materials` variable!
                // because it contains filtered values for the current surface and will override the global materials list
                setMaterials(prev => ([...prev, newMaterial]));
                setMsProps(prev => ({ ...prev, materials: [...prev.materials, newMaterial], selectedMaterial: newMaterial }));
                if (msProps.onSelectCallback) {
                    // trigger global callback
                    msProps.onSelectCallback(newMaterial);
                }
            }, setVisible: () => onBack(), setHeader: setHeader, onBack: onBack });
    }
    if (page === 'FLOOR_DETAILS') {
        return React.createElement(FloorDetails, { survey: survey, setPage: setPage, floors: floors, floor: floor, setFloor: setFloor, setHeader: setHeader, onBack: onBack });
    }
};
const UploadPlanTooltip = ({ primaryText, step, setStep, resetFloorPlan, setScalingPoints }) => {
    return React.createElement("div", { className: "text-white px-5 pt-4 pb-5 bg-sky-700 rounded-lg flex-col justify-center gap-4 flex w-full" },
        React.createElement("div", { className: "flex-col gap-3 flex" },
            React.createElement("div", { className: "font-bold" }, primaryText),
            React.createElement("div", { className: "items-center gap-3 flex" },
                step === 1
                    ? React.createElement(Button, { onClick: resetFloorPlan, iconLeft: faTimes }, "Cancel")
                    : React.createElement(Button, { onClick: () => { setScalingPoints(prev => prev.slice(0, -1)); setStep(prev => prev - 1); }, iconLeft: faChevronLeft }, "Back"),
                React.createElement("div", { className: "text-xs" },
                    "Step ",
                    step,
                    " of 3"))));
};
