import React, { useContext, useState } from 'react';
import { TableLite } from '../../components/content_display/table_lite';
import { Section } from '../../components/containers/section';
import { formatPrice } from '../../code/format_price';
import { Link } from '../../components/buttons/link';
import { formatDate } from '../../code/format_date';
import { orderBy } from 'lodash';
import { Button } from '../../components/buttons/button';
import { SendNudgeLeadEmailModal } from './components/send_nudge_lead_email_modal';
import { AdminContext } from './admin_layout';
export const HistoryPage = ({ lead, estimates, subdomain, reloadTrigger }) => {
    const adminContext = useContext(AdminContext);
    const [isEstimateModalVisible, setIsEstimateModalVisible] = useState(false);
    const [currentCalculatedQuote, setCurrentCalculatedQuote] = useState(undefined);
    const columns = [
        { name: 'Estimate', render: (x) => React.createElement("div", null,
                x.heat_pump_name,
                ", ",
                formatPrice(x.total_price)) },
        { name: 'Status', render: (x) => React.createElement("div", null, x.status) },
        { name: 'Date Sent', render: (x) => React.createElement("div", null, (x.created_at && formatDate(x.created_at)) || 'n/a') },
        { name: 'Date Reminder Sent', render: (x) => React.createElement("div", null, formatDate(x.reminder_sent_at)) },
        { name: '', render: (x) => React.createElement(Link, { text: 'View estimate', onClick: () => window.open(`/${subdomain}/estimate/${x.uuid}?installer=true`) }) },
        {
            name: '',
            render: (x) => {
                return React.createElement("div", { className: 'flex justify-end gap-4 items-center' }, (x.status === 'Sent' || x.status === 'Opened') &&
                    React.createElement(Button, { onClick: () => {
                            setCurrentCalculatedQuote(x);
                            setIsEstimateModalVisible(true);
                        } }, "Send reminder"));
            }
        }
    ];
    const orderedEstimates = orderBy(estimates, x => new Date((x.created_at || Date.now()).toString()), 'desc');
    return React.createElement("div", { className: 'p-8 gap-5 flex flex-col' },
        React.createElement(Section, { className: 'overflow-x-auto' },
            React.createElement(TableLite, { columns: columns, rows: orderedEstimates })),
        React.createElement(SendNudgeLeadEmailModal, { adminContext: adminContext, isVisible: isEstimateModalVisible, setIsVisible: setIsEstimateModalVisible, calculatedQuote: currentCalculatedQuote, lead: lead, reloadTrigger: reloadTrigger }));
};
