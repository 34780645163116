import { noop } from 'lodash';
import React, { useRef, useState } from 'react';
import { HLRContext } from '../heatloss_report';
import { getRoomsWithMetaData } from '../../floor/code/get_rooms_with_meta_data';
import { getStageScaleForRoom, getStageScaleForFloor, getStagePositionToCentreOnRoom, getStagePositionToCentreOnFloor, getNextWall, fixRotation } from '../../floor/code/utils';
import { FloorStage } from '../../floor/floor_stage';
export const HLRFloorplanBlock = ({ floor, room, width, height }) => {
    const hlrContext = React.useContext(HLRContext);
    const stageRef = useRef(null);
    const [stageWidth] = useState(width);
    // window.onresize = () => {
    //   stageRef.current && setStageWidth(stageRef.current.clientWidth)
    // }
    //
    // useEffect(() => {
    //   stageRef.current && setStageWidth(stageRef.current.clientWidth)
    // }, [stageRef])
    const scalingCoefficient = 0.8;
    // if room passed, scale on room, otherwise scale on floor
    const stageScale = room
        // SW: The scaleObject function that is called inside the getStageScaleForRoom function
        // has a bufferPixels input already which descries what the clearance should be around the room.
        // I played with using a multiplier when I first did this but it doesn't work very well because
        // for big rooms it feels very zoomed out. It would be better to play with the bufferPixels
        // argument inside the function here, or to pass that input into getStageScaleForRoom
        // if you need it to be configurable. I think it's only being used for this though
        // so shouldn't need multiple options.
        ? getStageScaleForRoom(room, stageWidth, height) * scalingCoefficient
        : getStageScaleForFloor(floor, stageWidth, height);
    // if room passed, center on room, otherwise center on floor
    const { x: stageX, y: stageY } = room
        ? getStagePositionToCentreOnRoom(room, stageScale, stageWidth, height)
        : getStagePositionToCentreOnFloor(floor, stageWidth, height, stageScale);
    const roomsWithMetaData = getRoomsWithMetaData(hlrContext.survey, floor, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.design, stageScale);
    const wallsWithoutDuplicatesReduce = (prev, curr) => {
        if (!prev.some(x => x.x1 === curr.x1 && x.x2 === curr.x2 && x.y1 === curr.y1 && x.y2 === curr.y2))
            return [...prev, curr];
        return prev;
    };
    const wallsWithoutDuplicates = floor.rooms
        .flatMap(r => r.walls.filter(x => x.other_room_uuid)
        .map(w => {
        const nextWall = getNextWall(w, r.walls);
        const line = { x1: w.x + r.x, x2: nextWall.x + r.x, y1: w.y + r.y, y2: nextWall.y + r.y };
        const lineRotated = fixRotation(line.x1, line.x2, line.y1, line.y2);
        return { ...lineRotated, uuid: w.uuid };
    }))
        .reduce(wallsWithoutDuplicatesReduce, []);
    return React.createElement("div", { className: 'rounded-xl overflow-hidden w-full self-center', ref: stageRef },
        React.createElement(FloorStage, { setIntersectingShapes: noop, survey: hlrContext.survey, scalingPoints: [], mousePosition: undefined, tempImageAndScale: { image: '', scale: 1 }, addEvent: noop, stageScale: stageScale, stagePosition: { x: stageX, y: stageY }, stageSize: { width: stageWidth, height }, defaultMaterials: hlrContext.survey.default_materials, roomsWithMetaData: roomsWithMetaData, currentRoom: undefined, currentRoomId: undefined, setCurrentRoomId: noop, wallsWithoutDuplicates: wallsWithoutDuplicates, floor: floor, setFloor: noop, dragStopped: false, currentWall: undefined, setStageScale: noop, page: 'FLOOR_DETAILS', setPage: noop, setCurrentWallId: noop, onMouseMove: noop, setWalls: noop, setStagePosition: noop, onClick: noop, onTouchEnd: noop, onDragMove: noop, onWheelProp: noop, onTouchMove: noop, isStatic: true, stageRef: undefined, setStageSize: noop, stageStep: undefined, isDrawing: false, intersectingShapes: [], scalingWindow: false, guidelines: [], setGuidelines: noop }));
};
